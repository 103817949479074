import RiskSideMenu from "../Components/RiskSideMenu";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {SendDelete, SendGet} from "../State/Helper";
import RiskTopMenu from "../Components/RiskTopMenu";

export default function BusinessActivity() {

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        mainSectionContainer: {
            flex: 1,
            backgroundColor: "#FFFFFF",
            padding: 20,
            display: 'flex',
            flexDirection: 'column'
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        mainButton: {
            color: '#000000',
            backgroundColor: '#FFFFFF',
            fontSize: 12,
            textTransform: 'uppercase',
            borderRadius: 5,
            padding: 4,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none',
            border: 'solid 1px #000000'
        },
        mainButtonIcon: {
            color: '#000000',
            verticalAlign: 'middle',
            fontSize: 20,
            fontWeight: 700
        },
        cardContainer: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            padding: 15,
            marginBottom: 10,
            borderRadius: 5,
            border: 'solid 1px #E2E2E2'
        },
        mainContainer: {
            marginTop: 20
        },
        flagIcon: {
            width: 50,
            height: 30,
            verticalAlign: 'middle',
            marginRight: 10
        },
        businessUnitLabel: {
            color: '#000000',
            fontWeight: '500'
        },
        actionIcon: {
            fontSize: 22,
            fontWeight: 600,
            verticalAlign: 'middle'
        },
        actionIconContainer: {
            padding: 8,
            borderRadius: '60%',
            marginRight: 10,
        }
    }
    const navigate = useNavigate();
    const [ba, setba] = useState([]);

    const getba = async () => {
        try
        {
            const response = await SendGet('api/v1/business-activities/all');

            if( response.data !== undefined && Array.isArray(response.data) )
            {
                setba(response.data);
            }
        }
        catch(Error)
        {
            console.log(Error);
        }
    }

    const deletebusinessactivity = async (id) => {
        if(window.confirm('Do you want to delete the business actvity?'))
        {
            try
            {
                await SendDelete(`api/v1/business-activities/${id}`);
                await getba();
            }
            catch(error)
            {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        getba();
    }, []);

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div>
                        <span className="btn-primary-custom" onClick={() => navigate("/bas/create")} style={styles.mainButton}><span className="material-symbols-outlined" style={styles.mainButtonIcon}>add</span></span>
                    </div>
                    <div style={styles.mainContainer}>
                        {
                            Array.isArray(ba) && ba.map(businessActivity => {
                                return (
                                    <div key={businessActivity.id} style={styles.cardContainer}>
                                        <div style={{ flex: 1 }}>
                                            <span style={styles.businessUnitLabel}>{businessActivity.description}</span>
                                        </div>
                                        <div style={{ float: 'right' }}>
                                            <Link to={`/bas/edit/${businessActivity.id}`} style={{ ...styles.actionIconContainer, borderColor: '#094392', marginTop: 20 }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: '#094392' }}>edit</span></Link>
                                            <span onClick={() => deletebusinessactivity(businessActivity.id)} style={{ ...styles.actionIconContainer, cursor: 'pointer', borderColor: 'red', marginTop: 20 }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: 'red' }}>delete</span></span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
