import RiskSideMenu from "../Components/RiskSideMenu";
import RiskUser from "../Components/RiskUser";
import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {SendGet} from "../State/Helper";
import RiskTopMenu from "../Components/RiskTopMenu";

export default function Users() {

    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [fullName, setFullName] = useState("");
    const [countries, setCountries] = useState([]);
    const [companies, setCompanies] = useState([]);

    const [country, setCountry] = useState("");
    const [company, setCompany] = useState("");

    const navigate = useNavigate();

    const getUsers = async () => {
        try
        {
            const rsp = await SendGet('api/v1/users');

            if( rsp.data !== undefined && Array.isArray(rsp.data) )
            {
                setUsers(rsp.data);
            }
        }
        catch(error)
        {
            console.log(error);
        }
    }

    const getCompanies = async () => {
        try
        {
            const response = await SendGet(`api/v1/companies`);
            if( response.data !== undefined && Array.isArray(response.data) )
            {
                setCompanies(response.data);
            }
        }
        catch(error)
        {
            console.log(error);
        }
    }

    const getCountries = async () => {
        try
        {
            const response = await SendGet(`api/v1/countries`);
            if( response.data !== undefined && Array.isArray(response.data) )
            {
                setCountries(response.data);
            }
        }
        catch(error)
        {
            console.log(error);
        }
    }

    useEffect(() => {
        async function fetchData() {
            await getCompanies();
            await getCountries();
            await getUsers();
        }
        fetchData();
    }, []);


    // Effect to filter users based on the input value
    useEffect(() => {
        if( users.length > 0 )
        {
            const filtered = users.filter(user =>
                `${user.firstName} ${user.lastName}`.toLowerCase().includes(fullName.toLowerCase())
            );
            setFilteredUsers(filtered);
        }
    }, [fullName, users]); // Dependencies: fullName and users

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row'
        },
        sideMenuContainer: {
        },
        contentContainer: {
            flex: 5,
            backgroundColor: "#FFFFFF",
            padding: 20
        },
        iconStyle: {
            color: '#000000',
            verticalAlign: 'middle',
            fontSize: 20,
            fontWeight: 700
        },
        mainTitle: {
            color: '#67D0FA',
            fontSize: 32,
            margin: 40
        },
        btnAjouter: {
            color: '#000000',
            backgroundColor: '#FFFFFF',
            fontSize: 12,
            textTransform: 'uppercase',
            borderRadius: 5,
            padding: 4,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none',
            border: 'solid 1px #000000'
        },
        limitContainer: {
            border: 'solid 1px #E2E2E2',
            borderRadius: 10,
            backgroundColor: '#FFFFFF',
            textAlign: 'center',
            padding: 20,
            margin: 40
        },
        limitLabel: {
            color: '#767676',
            fontSize: 14,
            fontWeight: 700
        },
        limitSelect: {
            backgroundColor: '#FFFFFF',
            borderRadius: 40,
            border: 'solid 1px #767676',
            padding: 8,
            fontSize: 14,
            marginLeft: 10,
            marginRight: 10,
            color: '#000000',
            fontWeight: 700
        },
        tabContainer: {
            display: 'flex',
            flexDirection: 'row',
            margin: 40
        },
        tab: {
            flex: 1.5,
            display: 'flex',
            flexDirection: 'column',
            padding: 20
        },
        textLabel: {
            fontSize: 14,
            fontWeight: 600,
            textTransform: 'uppercase',
            color: '#767676'
        },
        inputField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        selectField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #767676',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        usersContainer: {
            margin: 40,
            marginTop: 0
        },
        btnMove: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E1E4EB',
            borderRadius: 20,
            padding: 10,
            paddingLeft: 15,
            paddingRight: 15,
            cursor: 'pointer'
        },
        numberContainer: {
            backgroundColor: '#767676',
            width: 20,
            height: 20,
            padding: 5,
            paddingRight: 9,
            paddingLeft: 9,
            borderRadius: '50%'
        },
        numberText: {
            color: '#FFFFFF',
            fontSize: 12,
            fontWeight: 700
        }
    }

    return (
            <div>
                <RiskTopMenu />
                <div style={styles.container}>
                    <div style={styles.sideMenuContainer}>
                        <RiskSideMenu showSideMenu={true} selectedTab="" />
                    </div>
                    <div style={styles.contentContainer}>
                        <p>
                            <span className="btn-primary-custom" onClick={() => navigate("/users/create")} style={{ ...styles.btnAjouter, marginRight: 40 }}><span className="material-symbols-outlined" style={styles.iconStyle}>add</span></span>
                        </p>
                        <br />
                        <div style={styles.limitContainer}>
                            <span style={styles.limitLabel}>Afficher <select style={styles.limitSelect}><option>{users.length}</option></select> entrées</span>
                        </div>
                        <div style={styles.tabContainer}>
                            <div style={styles.tab}>
                                <span style={styles.textLabel}>nom complet <span className="material-symbols-outlined" style={{ ...styles.iconStyle, fontSize: 20, cursor: 'pointer' }}>sort_by_alpha</span></span>
                                <input type="text" style={styles.inputField} placeholder="Nom complet" onChange={(e) => setFullName(e.target.value)} />
                            </div>
                            <div style={styles.tab}>
                                <span style={styles.textLabel}>société <span className="material-symbols-outlined" style={{ ...styles.iconStyle, fontSize: 20, cursor: 'pointer' }}>sort_by_alpha</span></span>
                                <select style={styles.selectField} value={company} onChange={e => setCompany(e.target.value)}>
                                    {
                                        companies.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div style={styles.tab}>
                                <span style={styles.textLabel}>PAYS <span className="material-symbols-outlined" style={{ ...styles.iconStyle, fontSize: 20, cursor: 'pointer' }}>sort_by_alpha</span></span>
                                <select style={styles.selectField} value={country} onChange={e => setCountry(e.target.value)}>
                                    <option value="Tous">Tous</option>
                                    {
                                        countries.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div style={styles.usersContainer}>
                            {
                                Array.isArray(filteredUsers) && filteredUsers.map((user) => {
                                    return (
                                        <RiskUser key={user.id} fullName={`${user.firstName} ${user.lastName}`} id={user.id} tagType="pro" tagCompany="cmgp" />
                                    )
                                })
                            }
                        </div>
                        <div style={styles.limitContainer}>
                            <p style={styles.limitLabel}>Affichage de {users.length} sur {users.length} entées</p>
                            <span style={styles.limitLabel}><a style={styles.btnMove}>Précédente</a> <span style={styles.numberContainer}><span style={styles.numberText}>1</span></span> <a style={styles.btnMove}>Suivante</a></span>
                        </div>
                    </div>
                </div>
            </div>
    );
}
