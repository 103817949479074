import {Link} from "react-router-dom";

export default function RiskUser({ fullName, tagType, tagCompany, id }) {

    const styles = {
        container: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 10,
            padding: 5,
            marginTop: 20,
            border: 'solid 1px #E2E2E2'
        },
        textLabel: {
            color: '#000000',
            fontSize: 14,
            fontWeight: 700,
            textTransform: 'uppercase',
            margin: 10,
            marginTop: 20,
            flex: 1
        },
        tagLabel: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #000000',
            borderRadius: 8,
            height: 20,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 8,
            paddingBottom: 5,
            fontSize: 12,
            color: '#000000',
            margin: 10,
            cursor: 'pointer',
            flex: 0.5,
            textTransform: 'uppercase',
            textAlign: 'center'
        },
        iconStyle: {
            color: '#000000',
            fontSize: 18,
            verticalAlign: 'middle'
        },
        actionsContainer: {
            width: 200,
            marginTop: 10,
            flex: 5,
            textAlign: 'right'
        },
        actionIcon: {
            fontSize: 25,
            color: '#094392',
            verticalAlign: 'middle'
        },
        actionIconContainer: {
            padding: 8,
            borderRadius: '60%',
            marginRight: 10,
        }
    }

    return (
        <div style={styles.container}>
            <p style={styles.textLabel}><span className="material-symbols-outlined" style={{ ...styles.iconStyle, fontSize: 23 }}>person</span> {fullName}</p>
            <span style={styles.tagLabel}> {tagType}</span>
            <span style={styles.tagLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>house</span> {tagCompany}</span>
            <div style={styles.actionsContainer}>
                <Link to={`/users/edit/${id}`} style={{ ...styles.actionIconContainer, borderColor: '#000000', marginTop: 20 }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon }}>edit</span></Link>
            </div>
        </div>
    );
}
