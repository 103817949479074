import {useEffect, useState} from "react";
import {useAuth} from "../State/useAuth";

export default function FullMenuItem({ iconName, label, onClick })
{
    const [iconType, setIconType] = useState("material-symbols-outlined");
    const { activeMenu } = useAuth();

    const styles = {
        container: {
            backgroundColor: String(activeMenu).toLowerCase() === String(label).toLowerCase() ? "#F2F2F2" : "#FFFFFF",
            padding: 2,
            color: "#000000",
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
            margin: 5,
            borderRadius: 10,
            minWidth: 250
        },
        minMenuIcon: {
            fontSize: 25,
            margin: 10,
            verticalAlign: 'middle'
        },
        minMenuLabel: {
            fontSize: 15,
            fontWeight: String(activeMenu).toLowerCase() === String(label).toLowerCase() ? 600 : 400,
            paddingTop: 15,
            verticalAlign: 'middle'
        }
    }

    useEffect(() => {

        if( String(activeMenu).toLowerCase() === String(label).toLowerCase() )
        {
            setIconType("material-symbols-outlined material-symbols-filled");
        }
        else
        {
            setIconType("material-symbols-outlined");
        }

    }, [activeMenu]);

    return (
        <div style={styles.container} onClick={onClick} className="menu-item-container" >
            <span style={styles.minMenuIcon} className={iconType}  >{iconName}</span>
            <span style={styles.minMenuLabel}>{label}</span>
        </div>
    );
}
