import RiskSideMenu from "../Components/RiskSideMenu";
import frenchLogo from "../assets/images/french_logo.png";
import britainLogo from "../assets/images/britain.png";
import {useState, useEffect} from "react";
import {getConnectedUser, isEmpty, SendGet, SendPost, SendPut} from "../State/Helper";
import {useAuth} from "../State/useAuth";
import Success from "../Components/Success";
import Error from "../Components/Error";
import {useNavigate, useParams} from "react-router-dom";
import RiskTopMenu from "../Components/RiskTopMenu";

export default function RiskCreate () {

    const {id} = useParams();
    const [themes, setThemes] = useState([]);
    const [users, setUsers] = useState([]);
    const [potentialConsequences, setPotentialConsequences] = useState([]);
    const [processOwner, setProcessOwner] = useState('');
    const [frTitle, setFrTitle] = useState('');
    const [enTitle, setEnTitle] = useState('');
    const [theme, setTheme] = useState('');
    const [frDescription, setFrDescription] = useState('');
    const [enDescription, setEnDescription] = useState('');
    const [origin, setOrigin] = useState('');
    const [consequencePotential, setConsequencePotential] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [multipleCreation, setMultipleCreation] = useState(false);
    const { cartographie, country, company, businessUnit, businessActivity } = useAuth();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const connectedUser = getConnectedUser();
    const navigate = useNavigate();

    const [businessUnitList, setBusinessUnitList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [businessActivityList, setActivityList] = useState([]);

    const [buInput, setBuInput] = useState('');
    const [countryInput, setCountryInput] = useState('');
    const [companyInput, setCompanyInput] = useState('');
    const [baInput, setBaInput] = useState('');
    const [ActiveRisk, setActiveRisk] = useState(null);


    useEffect(() => {

        async function fetch() {
            if(!isEmpty(id))
            {
                const response = await SendGet(`api/v1/risks/${id}`);

                if (response.data) {

                    setActiveRisk(response.data);
                    setEditMode(true);
                    setConsequencePotential(JSON.parse(response.data.potentialConsequenceId));
                    setProcessOwner(response.data.creatorId);
                    setFrTitle(response.data.frTitle);
                    setEnTitle(response.data.enTitle);
                    setFrDescription(response.data.frDesc);
                    setEnDescription(response.data.enDesc);
                    setOrigin(response.data.origin);
                    setTheme(response.data.themeId);
                }
            }
        }
        fetch();
    }, []);

    const getThemes = async () => {

        try
        {
            let response = await SendGet(`api/v1/themes/mapping/${cartographie.value}`);

            if( !isEmpty(response.data) )
            {
                setThemes(response.data);
            }
        }
        catch(Exception)
        {
            console.log(Exception);
        }
    }

    const getUsers = async () => {

        try
        {
            const response = await SendGet('api/v1/users');
            if( !isEmpty(response.data) )
            {
                setUsers(response.data);
            }
        }
        catch(Exception)
        {
            console.log(Exception);
        }
    }

    async function getPotentialConsequences() {

        try
        {
            const response = await SendGet(`api/v1/potentialConsequences/mapping/${cartographie.value}`);
            if( response.data !== undefined && Array.isArray(response.data) && response.data.length > 0 )
            {
                const potentialConseq = response.data;
                const filteredConseq = potentialConseq.filter((item) => item.mappingId === cartographie.value);
                setPotentialConsequences(filteredConseq);
            }
        }
        catch(Exception)
        {
            console.log(Exception);
        }
    }

    const createRisk = async () => {

        if( frTitle === '' || enTitle === '' || processOwner === '' || theme === '' || frDescription === '' || enDescription === '' || origin === '' || isEmpty(consequencePotential) )
        {
            setError("Veuillez remplir tous les champs obligatoires !");
            return;
        }

        try
        {
            await SendPost('api/v1/risks', {
                "creatorId": connectedUser.id,
                "countryId": country.value,
                "companyId": company.value,
                "buId": businessUnit.value,
                "baId": businessActivity.value,
                "mappingId": cartographie.value,
                "enTitle": enTitle,
                "frTitle": frTitle,
                "enDesc": enDescription,
                "frDesc": frDescription,
                "origin": origin,
                "potentialConsequenceId": JSON.stringify(consequencePotential),
                "status": 'DRAFT',
                "themeId": theme,
                "reevaluate": 0,
                "credate": Date.now()
            });

            setFrTitle(``);
            setEnTitle(``);
            setProcessOwner(``);
            setTheme(``);
            setFrDescription(``);
            setEnDescription(``);
            setOrigin(``);
            setConsequencePotential([]);

            navigate(`/grid`);
        }
        catch(Exception)
        {
            console.log(Exception);
        }
    }

    const updateRisk = async () => {

        if( frTitle === '' || enTitle === '' || processOwner === '' || theme === '' || frDescription === '' || enDescription === '' || origin === '' || isEmpty(consequencePotential) )
        {
            setError("Please fill all the required fields!");
            return;
        }

        try
        {
            await SendPut(`api/v1/risks/${id}`, {
                "creatorId": ActiveRisk.creatorId,
                "countryId": ActiveRisk.countryId,
                "companyId": ActiveRisk.companyId,
                "buId": ActiveRisk.buId,
                "baId": ActiveRisk.baId,
                "mappingId": ActiveRisk.mappingId,
                "enTitle": enTitle,
                "frTitle": frTitle,
                "enDesc": enDescription,
                "frDesc": frDescription,
                "origin": origin,
                "potentialConsequenceId": JSON.stringify(consequencePotential),
                "status": 'DRAFT',
                "themeId": theme
            });

            setSuccess("Risk updated successfully.");

            navigate(`/risks/detail/${id}`);
        }
        catch(Exception)
        {
            console.log(Exception);
        }
    }

    const handleCheckboxChange = (e) => {
        try {
            const id = e.target.value;

            setConsequencePotential((prevConsequencePotential) => {
                if (e.target.checked) {
                    return [...prevConsequencePotential, id];
                } else {
                    return prevConsequencePotential.filter(
                        (checkedId) => checkedId !== id
                    );
                }
            });

        } catch (Exception) {
            console.error(Exception);
        }
    };

    const getBusinessUnitList = async () => {
        const response = await SendGet(`api/v1/bus`);
        if( !isEmpty(response.data) )
        {
            setBusinessUnitList(response.data);
        }
    }

    const getCountryList = async () => {
        const response = await SendGet(`api/v1/countries`);
        if( !isEmpty(response.data) )
        {
            setCountryList(response.data);
        }
    }

    const getCompanyList = async () => {
        const response = await SendGet(`api/v1/companies`);
        if( !isEmpty(response.data) )
        {
            setCompanyList(response.data);
        }
    }

    const getBusinessActivityList = async () => {
        const response = await SendGet(`api/v1/business-activities`);
        if( !isEmpty(response.data) )
        {
            setActivityList(response.data);
        }
    }

    const createRiskMultiple = async () => {

        if ( frTitle === "" || enTitle === "" || processOwner === "" || theme === "" || frDescription === "" || enDescription === "" || origin === "" )
        {
            setError(`Veuillez remplir tous les champs obligatoires.`);
            return;
        }

        let selectedBusinessUnits = [];
        let selectedCountries = [];
        let selectedCompanies = [];
        let selectedBusinessActivities = [];

        if( !isEmpty(buInput) && Array.isArray(buInput) )
        {
            if( buInput[0].value === "tout" )
            {
                selectedBusinessUnits = businessUnitList;
            }
            else
            {
                buInput.map(item => {
                    selectedBusinessUnits.push({ id: item.value });
                });
            }
        }

        if( !isEmpty(countryInput) && Array.isArray(countryInput) )
        {
            if( countryInput[0].value === "tout" )
            {
                selectedCountries = countryList;
            }
            else
            {
                countryInput.map(item => {
                    selectedCountries.push({ id: item.value });
                });
            }
        }

        if( !isEmpty(companyInput) && Array.isArray(companyInput) )
        {
            if( companyInput[0].value === "tout" )
            {
                selectedCompanies = companyList;
            }
            else
            {
                companyInput.map(item => {
                    selectedCompanies.push({ id: item.value });
                });
            }
        }

        if( !isEmpty(baInput) && Array.isArray(baInput) )
        {
            if( baInput[0].value === "tout" )
            {
                selectedBusinessActivities = businessActivityList;
            }
            else
            {
                baInput.map(item => {
                    selectedBusinessActivities.push({ id: item.value });
                });
            }
        }

        const promises = [];

        for (const bu of selectedBusinessUnits)
        {
            for (const country of selectedCountries)
            {
                for (const company of selectedCompanies)
                {
                    for (const ba of selectedBusinessActivities)
                    {
                        promises.push(
                            SendPost('api/v1/risks', {
                                "creatorId": connectedUser.id,
                                "countryId": country.id,
                                "companyId": company.id,
                                "buId": bu.id,
                                "baId": ba.id,
                                "mappingId": cartographie.value,
                                "enTitle": enTitle,
                                "frTitle": frTitle,
                                "enDesc": enDescription,
                                "frDesc": frDescription,
                                "origin": origin,
                                "potentialConsequenceId": JSON.stringify(consequencePotential),
                                "status": 'DRAFT',
                                "themeId": theme,
                                "reevaluate": 0,
                                "credate": Date.now()
                            })
                        );
                    }
                }
            }
        }

        await Promise.all(promises);

        setSuccess(`Risks created successfully.`);
    };

    useEffect(() => {
        async function fetchData () {
            await getBusinessUnitList();
            await getCountryList();
            await getCompanyList();
            await getBusinessActivityList();
            await getThemes();
            await getUsers();
            await getPotentialConsequences();
        }
        fetchData().catch(e => console.log(e));
    }, []);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row'
        },
        menuCol: {
            margin: 10
        },
        bodyCol: {
            flex: 5,
            backgroundColor: '#FFFFFF'
        },
        mainTitle: {
            fontSize: 30,
            marginLeft: 20,
            color: '#67D0FA'
        },
        subTitle: {
            fontSize: 35,
            fontWeight: 600,
            color: '#094492',
            marginLeft: 20,
            marginTop: -20
        },
        formContainer: {
            backgroundColor: '#FFFFFF',
            width: '70%',
            margin: '0px auto',
            marginTop: 20,
            borderRadius: 10,
            marginBottom: 20
        },
        formRow: {
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
            paddinTop: -20
        },
        formRowLabel: {
            textTransform: 'uppercase',
            color: '#000000',
            fontWeight: 500,
            fontSize: 14,
            marginBottom: 5
        },
        formRowInput: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000'
        },
        iconStyle: {
            fontSize: 20,
            verticalAlign: 'middle',
            marginRight: 5
        },
        selectField: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        }
    }

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.container}>
                <div style={styles.menuCol}>
                    <RiskSideMenu showSideMenu={true} />
                </div>
                <div style={styles.bodyCol}>
                    <div style={styles.formContainer}>

                        <Success isVisible={success != null} sucessMessage={success} handleClose={() => setSuccess(null)} />
                        <Error isVisible={error != null} errorMessage={error} handleClose={() => setError(null)} />

                        <div style={styles.formRow}>
                            <span style={styles.formRowLabel}><img src={frenchLogo} style={{ width: 20, height: 15, marginRight: 5, borderRadius: 5, verticalAlign: 'middle' }} /> Titre français<span className="material-symbols-outlined" style={{ ...styles.iconStyle, color: '#FFFFFF', backgroundColor: '#094492', borderRadius: '50%', fontSize: 18, marginLeft: 10, cursor: 'pointer' }}>info</span></span>
                            <input type="text" style={styles.formRowInput} value={frTitle} onChange={e => setFrTitle(e.target.value)} />
                        </div>
                        <div style={styles.formRow}>
                            <span style={styles.formRowLabel}><img src={britainLogo} style={{ width: 20, height: 15, marginRight: 5, borderRadius: 5, verticalAlign: 'middle' }} /> Titre Anglais<span className="material-symbols-outlined" style={{ ...styles.iconStyle, color: '#FFFFFF', backgroundColor: '#094492', borderRadius: '50%', fontSize: 18, marginLeft: 10, cursor: 'pointer' }}>info</span></span>
                            <input type="text" style={styles.formRowInput} value={enTitle} onChange={e => setEnTitle(e.target.value)} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ ...styles.formRow, flex: 3 }}>
                                <span style={styles.formRowLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>person</span>Risk process owner<span className="material-symbols-outlined" style={{ ...styles.iconStyle, color: '#FFFFFF', backgroundColor: '#094492', borderRadius: '50%', fontSize: 18, marginLeft: 10, cursor: 'pointer' }}>info</span></span>
                                <select style={styles.selectField} value={processOwner} onChange={e => setProcessOwner(e.target.value)}>
                                    <option value="" />
                                    {
                                        !isEmpty(users) && users.map(item => {
                                            return (
                                                <option key={item.id} value={item.id}>{`${item.firstName} ${item.lastName}`}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div style={{ ...styles.formRow, flex: 3 }}>
                                <span style={styles.formRowLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>tag</span>thème<span className="material-symbols-outlined" style={{ ...styles.iconStyle, color: '#FFFFFF', backgroundColor: '#094492', borderRadius: '50%', fontSize: 18, marginLeft: 10, cursor: 'pointer' }}>info</span></span>
                                <select style={styles.selectField} value={theme} onChange={e => setTheme(e.target.value)}>
                                    <option value="" />
                                    {
                                        !isEmpty(themes) && themes.map(item => {
                                            return (
                                                <option key={item.id} value={item.id}>{item.frTitle}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div style={styles.formRow}>
                            <span style={styles.formRowLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>description</span>Description française<span className="material-symbols-outlined" style={{ ...styles.iconStyle, color: '#FFFFFF', backgroundColor: '#094492', borderRadius: '50%', fontSize: 18, marginLeft: 10, cursor: 'pointer' }}>info</span></span>
                            <textarea onChange={e => setFrDescription(e.target.value)} style={{ flex: 1, fontSize: 14, color: '#000000', fontWeight: 600, paddingLeft: 10, paddingTop: 10, border: 'solid 1px #D6D7D9', borderRadius: 10, minHeight: 200, maxHeight: 900 }} value={frDescription} />
                        </div>
                        <div style={styles.formRow}>
                            <span style={styles.formRowLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>description</span>Description anglaise<span className="material-symbols-outlined" style={{ ...styles.iconStyle, color: '#FFFFFF', backgroundColor: '#094492', borderRadius: '50%', fontSize: 18, marginLeft: 10, cursor: 'pointer' }}>info</span></span>
                            <textarea onChange={e => setEnDescription(e.target.value)} style={{ flex: 1, fontSize: 14, color: '#000000', fontWeight: 600, border: 'solid 1px #D6D7D9', paddingLeft: 10, paddingTop: 10, borderRadius: 10, minHeight: 200, maxHeight: 900 }} value={enDescription} />
                        </div>
                        <div style={styles.formRow}>
                            <span style={styles.formRowLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>search</span>ORIGINE</span>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <p style={{ flex: 1, color: '#000000', fontSize: 14, fontWeight: 600 }}><input value="Interne" onChange={e => setOrigin(e.target.value)} type="radio" name="origine" checked={origin === 'Interne'} /><span>Interne</span></p>
                                <p style={{ flex: 1, color: '#000000', fontSize: 14, fontWeight: 600 }}><input value="Externe" onChange={e => setOrigin(e.target.value)} type="radio" name="origine" checked={origin === 'Externe'} /><span>Externe</span></p>
                                <p style={{ flex: 1, color: '#000000', fontSize: 14, fontWeight: 600 }}><input value="Interne & Externe" onChange={e => setOrigin(e.target.value)} type="radio" name="origine" checked={origin === 'Interne & Externe'}  /><span>Interne et externe</span></p>
                            </div>
                        </div>

                        {
                            !editMode &&
                            <>
                                <input
                                    type="checkbox"
                                    value={multipleCreation}
                                    style={{ marginLeft:25, marginBottom: 20 }}
                                    onChange={e => {
                                        if(e.target.checked)
                                        {
                                            setMultipleCreation(true);
                                        }
                                        else
                                        {
                                            setMultipleCreation(false);
                                        }
                                    }} /> <span style={{ color: '#000000', fontWeight: 700, fontSize: 14 }}>Création multiple</span>
                            </>
                        }

                        {
                            multipleCreation &&
                            <div>

                                <div style={styles.formRow}>
                                    <span style={styles.formRowLabel}>Business unit</span>
                                    <select multiple={true} style={{ ...styles.formRowInput, backgroundColor: '#FFFFFF', paddingTop: 10, paddingBottom: 10, maxHeight: 100 }} onChange={ (e) => {
                                        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
                                        setBuInput(selectedOptions.map(value => ({ value })));
                                    } } >
                                        <option value="tout">Tout</option>
                                        {
                                            !isEmpty(businessActivityList) && businessUnitList.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>

                                <div style={styles.formRow}>
                                    <span style={styles.formRowLabel}>Country</span>
                                    <select multiple={true} style={{ ...styles.formRowInput, backgroundColor: '#FFFFFF', paddingTop: 10, paddingBottom: 10, maxHeight: 100 }} onChange={e => {
                                        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
                                        setCountryInput(selectedOptions.map(value => ({ value })));
                                    }} >
                                        <option value="tout">Tout</option>
                                        {
                                            !isEmpty(countryList) && countryList.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>

                                <div style={styles.formRow}>
                                    <span style={styles.formRowLabel}>Company</span>
                                    <select multiple={true} style={{ ...styles.formRowInput, backgroundColor: '#FFFFFF', paddingTop: 10, paddingBottom: 10, maxHeight: 100 }} onChange={e => {
                                        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
                                        setCompanyInput(selectedOptions.map(value => ({ value })));
                                    }} >
                                        <option value="tout">Tout</option>
                                        {
                                            !isEmpty(companyList) && companyList.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>

                                <div style={styles.formRow}>
                                    <span style={styles.formRowLabel}>Business activity</span>
                                    <select multiple={true} style={{ ...styles.formRowInput, backgroundColor: '#FFFFFF', paddingTop: 10, paddingBottom: 10, maxHeight: 100 }} onChange={e => {
                                        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
                                        setBaInput(selectedOptions.map(value => ({ value })));
                                    }} >
                                        <option value="tout">Tout</option>
                                        {
                                            !isEmpty(businessActivityList) && businessActivityList.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.description}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>

                            </div>
                        }

                        <hr style={{ color: '#D1E5FE', margin: 30, marginTop: 5, marginBottom: 10 }} />
                        <div style={styles.formRow}>
                            <span style={styles.formRowLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>local_fire_department</span>Conséquences potentielles</span>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {
                                    !isEmpty(potentialConsequences) && potentialConsequences.map(item => {

                                        return (
                                            <div style={{ flex: 1, color: '#000000', fontSize: 14 }} key={item.id}>
                                                <input
                                                    type="checkbox"
                                                    value={item.id}
                                                    onChange={handleCheckboxChange}
                                                    checked={consequencePotential.includes(String(item.id))}
                                                />{' '}
                                                <span
                                                    className="material-symbols-outlined"
                                                    style={{ fontSize: 18, fontWeight: 700 }}
                                                >
                                                {item.icon}
                                              </span>{' '}
                                                <span>{item.frTitle}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div style={{ padding: 40, paddingBottom: 10, textAlign: 'center' }}>
                                <button onClick={() => navigate("/grid")} style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> Fermer
                                </button>
                                {!editMode && !multipleCreation && <button onClick={createRisk} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Créer
                                </button>}
                                {multipleCreation && <button onClick={createRiskMultiple} style={{ fontSize: 14, color: '#FFFFFF', padding: 10, borderRadius: 20, border: 'none', backgroundColor: '#2F87FC', paddingLeft: 40, paddingRight: 40, fontWeight: 700, textTransform: 'uppercase', cursor: 'pointer', marginLeft: 10 }}>Création multiple</button> }
                                { editMode && <button onClick={updateRisk} style={{ fontSize: 14, color: '#FFFFFF', padding: 10, borderRadius: 20, border: 'none', backgroundColor: '#2F87FC', paddingLeft: 40, paddingRight: 40, fontWeight: 700, textTransform: 'uppercase', cursor: 'pointer' }}>Modifier</button> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
