import { useEffect, useState } from "react";
import RiskCardList from "./RiskCardList";
import { SendGet, isEmpty } from "../State/Helper";
import { useAuth } from "../State/useAuth";
import { primaryColor } from "../configuration/colorPalet";

export default function MappingContainer({ mapping }) {

    const [riskList, setRiskList] = useState([]);

    const [safeRiskCounter, setSafeRiskCounter] = useState(0);
    const [mediumRiskCounter, setMediumRiskCounter] = useState(0);
    const [warningRiskCounter, setWarningRiskCounter] = useState(0);
    const [criticalRiskCounter, setCriticalRiskCounter] = useState(0);
    const [expended, setExpended] = useState(false);
    const {searchKeyword} = useAuth();

    // Setting counters
    const fetchRiskData = async () => {

        let safeCounter = 0;
        let mediumCounter = 0;
        let warningCounter = 0;
        let criticalCounter = 0;

        try {

            // Get the risks available for the current user only
            const riskResponse = await SendGet(`api/v1/risks/currentuser`);

            let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

            let filteredRisks = riskResponse.data;

            // Filter risks based on the keyword
            if( parseSearchKeyword !== null )
            {
                filteredRisks = riskResponse.data.filter(risk => ( risk.enTitle.includes(parseSearchKeyword) || risk.frTitle.includes(parseSearchKeyword) ))
            }

            // Check if the response is empty
            if( !isEmpty(filteredRisks) )
            {
                // Filter the risks to get only the ones for that specific mapping
                filteredRisks = filteredRisks.filter(risk => parseInt(risk.mappingId) === parseInt(mapping.id));

                // Loop through all the risks
                for (let item of filteredRisks)
                {
                    // Get the assessment for the risk
                    let assessmentResponse = await SendGet(`api/v1/assessments/risk/${item.id}`);

                    // Check if it's evaluated or not
                    if( !isEmpty(assessmentResponse.data) )
                    {
                        // Get active assessment
                        let assessment = assessmentResponse.data.reduce((max, obj) => parseInt(obj.version) > parseInt(max.version) ? obj : max);

                        if( !isEmpty(assessment) )
                        {
                            let riskNetNormalise = parseFloat(assessment.riskNetNormalise).toFixed(2);

                            if (riskNetNormalise <= 3)
                            {
                                safeCounter += 1;
                            }
                            else if (riskNetNormalise > 3 && riskNetNormalise <= 4)
                            {
                                mediumCounter += 1;
                            }
                            else if (riskNetNormalise > 4 && riskNetNormalise <= 5)
                            {
                                warningCounter += 1;
                            }
                            else if (riskNetNormalise > 5 && riskNetNormalise <= 6)
                            {
                                criticalCounter += 1;
                            }
                        }
                    }
                }
            }

            setSafeRiskCounter(safeCounter);
            setMediumRiskCounter(mediumCounter);
            setWarningRiskCounter(warningCounter);
            setCriticalRiskCounter(criticalCounter);

        } catch (error)
        {
            console.error("An error occurred while fetching risk data:", error);
        }
    };

    useEffect(() => {
        async function fetchData()
        {
            await fetchRiskData();
            await getRiskList();
        }
        fetchData();
    }, [mapping, expended, searchKeyword]);

    // Get the actual risks data
    async function getRiskList() {

        // Get the risks for the current active user
        let response = await SendGet(`api/v1/risks/currentuser`);

        let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

        // Filter risks based on the keyword
        let filteredRisks = response.data;

        if( parseSearchKeyword !== null )
        {
            filteredRisks = response.data.filter(risk => ( risk.enTitle.includes(parseSearchKeyword) || risk.frTitle.includes(parseSearchKeyword) ));
        }

        let assessedRisks = [];

        // Check if the response has some data
        if ( !isEmpty(filteredRisks) && expended !== false ) {

            await Promise.all(filteredRisks.map(async risk => {
                assessedRisks.push({ ...risk, calcs: await getAssessment(risk.id) });
            }));

            // Filter the risks based on mapping
            assessedRisks = assessedRisks.filter(risk => parseInt(risk.mappingId) === parseInt(mapping.id));

            // Filter not evaluated
            assessedRisks = assessedRisks.filter(risk => !isEmpty(risk.calcs));

            // Sort the array
            assessedRisks = assessedRisks.sort((a, b) => a.credate - b.credate);

            let expandFiltered = [];

            // filter by the type
            if( parseInt(expended) === 1 )
            {
                expandFiltered = assessedRisks.filter(item => parseFloat(item.calcs.riskNetNormalise).toFixed(2) <= 3);

            } else if ( parseInt(expended) === 2 )
            {
                expandFiltered = assessedRisks.filter(item => parseFloat(item.calcs.riskNetNormalise).toFixed(2) > 3 && parseFloat(item.calcs.riskNetNormalise).toFixed(2) <= 4);

            } else if ( parseInt(expended) === 3 )
            {
                expandFiltered = assessedRisks.filter(item => parseFloat(item.calcs.riskNetNormalise).toFixed(2) > 4 && parseFloat(item.calcs.riskNetNormalise).toFixed(2) <= 5);

            } else if ( parseInt(expended) === 4 )
            {
                expandFiltered = assessedRisks.filter(item => parseFloat(item.calcs.riskNetNormalise).toFixed(2) > 5 && parseFloat(item.calcs.riskNetNormalise).toFixed(2) <= 6);
            }

            setRiskList(expandFiltered);
        }
    }

    async function getAssessment(riskId) {

        let assessments = await SendGet(`api/v1/assessments/risk/${riskId}`);

        if( !isEmpty(assessments) )
        {
            return assessments.data.reduce((max, obj) => (parseInt(obj.version) > parseInt(max.version) ? obj : max), assessments.data[0]);
        }
        else
        {
            return null;
        }
    }

    function handleClick(id) {

        if( parseInt(expended) !== parseInt(id) )
        {
            setExpended(id);
        }
        else
        {
            setExpended(false);
            setRiskList([]);
        }
    }

    const styles = {
        riskContainer: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E2E2E2',
            padding: 10,
            paddingLeft: 20,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            marginBottom: 20
        },
        label: {
            fontSize: 12,
            color: primaryColor,
            fontWeight: 700,
            textTransform: 'uppercase'
        },
        iconStyle: {
            fontSize: 16,
            verticalAlign: 'middle',
            fontWeight: 700
        },
        cartographieDetails: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 20
        }
    }

    return (
        <div style={styles.riskContainer} >

            <div style={{ marginBottom: 10, margin: 10 }}>
                <span style={styles.label}>{mapping.name}</span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
                <div onClick={() => handleClick(1)} style={{ padding: 10, flex: 1.5, borderTopLeftRadius: 20, borderBottomLeftRadius: 20, backgroundColor: '#54B872' }}>
                    { expended !== 1 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{safeRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>navigate_next</span></span> }
                    { expended === 1 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{safeRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>expand_more</span></span> }
                </div>
                <div onClick={() => handleClick(2)} style={{ padding: 10, flex: 1.5, backgroundColor: '#CEC340' }}>
                    { expended !== 2 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{mediumRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>navigate_next</span></span> }
                    { expended === 2 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{mediumRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>expand_more</span></span> }
                </div>
                <div onClick={() => handleClick(3)} style={{ padding: 10, flex: 1.5, backgroundColor: '#D78434' }}>
                    { expended !== 3 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{warningRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>navigate_next</span></span> }
                    { expended === 3 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{warningRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>expand_more</span></span> }
                </div>
                <div onClick={() => handleClick(4)} style={{ padding: 10, flex: 1.5, borderTopRightRadius: 20, borderBottomRightRadius: 20, backgroundColor: '#D64C47' }}>
                    { expended !== 4 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{criticalRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>navigate_next</span></span> }
                    { expended === 4 && <span style={{ color: '#FFFFFF', fontWeight: 700, fontSize: 14, backgroundColor: '#000000', borderRadius: 10, padding: 5, paddingLeft: 10, cursor: 'pointer' }}>{criticalRiskCounter} <span className="material-symbols-outlined" style={styles.iconStyle}>expand_more</span></span> }
                </div>
            </div>

            {
                expended &&
                <div style={styles.cartographieDetails}>
                    {
                        !isEmpty(riskList) && riskList.map((item, index) => {

                            return (
                                <RiskCardList key={index} frTitle={item.frTitle} risk={item} />
                            )
                        })
                    }
                </div>
            }

        </div>
    );
}
