import RiskSideMenu from "../Components/RiskSideMenu";
import {useEffect, useState} from "react";
import {getConnectedUser, isEmpty, SendGet} from "../State/Helper";
import RiskTopMenu from "../Components/RiskTopMenu";

export default function Profile() {

    const [user, setUser] = useState({id: '', company: '', firstName: '', lastName: '', email: '', status: '', langue: '' });
    const [permissions, setPermissions] = useState([]);
    const [authorizations, setAuthorizations] = useState([]);
    const currentUser = getConnectedUser();

    const getUser = async () => {

        try
        {
            const userData = JSON.parse(localStorage.getItem("user"));
            const rsp = await SendGet(`api/v1/users/${userData.id}`);

            if( !isEmpty(rsp.data) )
            {
                setUser({
                    id: rsp.data.id,
                    company: rsp.data.company?.name,
                    firstName: rsp.data?.firstName,
                    lastName: rsp.data?.lastName,
                    email: rsp.data?.email,
                    langue: rsp.data?.language,
                    status: rsp.data?.status
                });
            }
        }
        catch(error)
        {
            console.log(error);
        }
    }

    async function getPermissions() {
        const response = await SendGet(`api/v1/userauths/user/${currentUser.id}`);
        if( !isEmpty(response.data) )
        {
            setPermissions(response.data);
        }
        else
        {
            setPermissions([]);
        }
    }

    async function getAuthorizations() {
        const response = await SendGet(`api/v1/authorizations`);
        if( !isEmpty(response.data) )
        {
            setAuthorizations(response.data)
        }
        else
        {
            setAuthorizations([]);
        }
    }

    useEffect(() => {
        async function fetch() {
            await getUser();
            await getAuthorizations();
            await getPermissions();
        }
        fetch().catch(e => console.log(`Error: `, e));
    }, []);

    const styles = {
        mainContainer: {
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#FFFFFF'
        },
        container: {
            margin: 40,
            display: 'flex',
            flexDirection: 'column'
        },
        contentContainer: {
            margin: 40,
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            padding: 20,
            flex: 1,
            minWidth: 600,
        },
        label: {
            fontSize: 13,
            textTransform: 'uppercase',
            color: '#767676'
        },
        textValue: {
            fontSize: 14,
            fontWeight: 700,
            color: '#000000'
        },
        rowContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        colContainer: {
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            flex: 3
        },
        iconStyle: {
            color: '#767676',
            fontSize: 18,
            verticalAlign: 'middle',
            paddingBottom: 4
        },
        mainTitle: {
            fontSize: 26,
            color: '#000000',
            marginLeft: 40
        },
        secondaryTitle: {
            fontSize: 28,
            color: '#767676',
            marginTop: 10,
            marginLeft: 40
        },
        seperator: {
            backgroundColor: '#E2E2E2',
            height: 1
        },
        checkboxStyle: {
            color: '#094492',
            height: 20,
            width: 20,
            verticalAlign: 'middle'
        },
        roleContainer: {
            backgroundColor: '#FFFFFF',
            padding: 20,
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10
        },
        roleBtn: {
            color: '#000000',
            fontSize: 10,
            paddingLeft: 10,
            paddingRight: 10,
            float: 'right',
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            marginLeft: 5,
            padding: 3
        },
        roleLabel: {
            fontSize: 14,
            color: '#000000',
            fontWeight: 700,
            flex: 1
        }
    }

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.mainContainer}>
                <RiskSideMenu showSideMenu={true} />
                <div style={styles.container}>
                    <span style={styles.mainTitle}>Mes informations</span>
                    <span style={styles.secondaryTitle}>Administrateur</span>
                    <div style={styles.contentContainer}>
                        <div style={styles.rowContainer}>
                            <div style={styles.colContainer}>
                                <span style={styles.label}><span className="material-symbols-outlined" style={styles.iconStyle}>mail</span> Email</span>
                                <span style={styles.textValue}>{user.email}</span>
                            </div>
                            <div style={styles.colContainer}>
                                <span style={styles.label}><span className="material-symbols-outlined" style={styles.iconStyle}>person</span> Prenom</span>
                                <span style={styles.textValue}>{user.firstName}</span>
                            </div>
                        </div>
                        <div style={styles.rowContainer}>
                            <div style={styles.colContainer}>
                                <span style={styles.label}><span className="material-symbols-outlined" style={styles.iconStyle}>person</span> Nom</span>
                                <span style={styles.textValue}>{user.lastName}</span>
                            </div>
                            <div style={styles.colContainer}>
                                <span style={styles.label}><span className="material-symbols-outlined" style={styles.iconStyle}>how_to_reg</span> Statut</span>
                                <span style={styles.textValue}>{user.status}</span>
                            </div>
                        </div>
                        <div style={styles.rowContainer}>
                            <div style={styles.colContainer}>
                                <span style={styles.label}><span className="material-symbols-outlined" style={styles.iconStyle}>house</span> Société</span>
                                <span style={styles.textValue}>{user.company}</span>
                            </div>
                            <div style={styles.colContainer}>
                                <span style={styles.label}><span className="material-symbols-outlined" style={styles.iconStyle}>flag</span> Langue</span>
                                <span style={styles.textValue}>{user.langue}</span>
                            </div>
                        </div>

                        <div style={styles.seperator}></div>

                        <div style={styles.rowContainer}>
                            <div style={styles.colContainer}>
                                <span style={styles.label}><span className="material-symbols-outlined" style={styles.iconStyle}>notifications</span> Notifications</span>
                                <span style={styles.textValue}><input type="checkbox" style={styles.checkboxStyle} /> Recevoir mes notifications par e-mail</span>
                            </div>
                        </div>

                        <div style={styles.seperator}></div>

                        <div style={styles.rowContainer}>
                            <div style={styles.colContainer}>
                                <span style={styles.label}><span className="material-symbols-outlined" style={styles.iconStyle}>manage_accounts</span> Mes droits</span>
                            </div>
                        </div>

                        <div style={styles.roleContainer}>
                            {
                                permissions.map(permission => {

                                    let authorization = authorizations.find(authorization => authorization.id === permission.authorizationId);

                                    return (
                                        <div key={permission.id}><span style={styles.roleBtn}><span className="material-symbols-outlined" style={{ ...styles.iconStyle, color: '#000000', fontWeight: 600 }}>manage_accounts</span> {authorization.authorizationName}</span></div>
                                    )
                                })
                            }
                        </div>

                        <div style={styles.seperator}></div>

                        <div style={styles.rowContainer}>
                            <div style={styles.colContainer}>
                                <span style={styles.label}><span className="material-symbols-outlined" style={styles.iconStyle}>mail</span> Contact</span>
                                <span style={styles.roleLabel}>Pour modifier vos informations, vos droits d'accès aux cartographies ou vous désincrire, merci de</span>
                                <span style={{ ...styles.roleLabel, color: '#767676', cursor: 'pointer', marginTop: 5 }}>contacter l'administrateur</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
