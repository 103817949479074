export default function GridItem ({ occurrence, impact }) {

    const cellWidth = 900/6;
    const cellHeight = 600/6;
    const labelSize = 20;

    const xPos = ( parseFloat(impact).toFixed(1) - 1) * cellWidth + (cellWidth - labelSize) / 2;
    const yPos = ( ( parseFloat(occurrence).toFixed(1) - 1) * cellHeight + (cellHeight - labelSize) / 2 ) - labelSize;

    return (
        <div
            style={{
                position: 'absolute',
                left: `${xPos}px`,
                bottom: `${yPos}px`,
                backgroundColor: '#2F87FC',
                borderRadius: '12%',
                padding: 2,
                paddingTop: 5,
                paddingBottom: 5,
                width: 40,
                textAlign: 'center',
                fontWeight: 700,
                color: '#FFFFFF',
            }}
        >
            <span style={{ color: '#FFFFFF' }}>{`${occurrence}:${impact}`}</span>
        </div>
    );
};
