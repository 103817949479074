export default function RiskCartographie({ textLabel, tagName, username, onEdit, onDelete }) {

    const styles = {
        container: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 10,
            padding: 10,
            marginTop: 20,
            border: 'solid 1px #D1E4FE'
        },
        textLabel: {
            color: '#094392',
            fontSize: 14,
            fontWeight: 700,
            textTransform: 'uppercase',
            margin: 10,
            marginTop: 20,
            flex: 0.5
        },
        tagLabel: {
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #2F87FB',
            borderRadius: 8,
            height: 20,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 5,
            fontSize: 12,
            color: '#2F87FB',
            margin: 10,
            cursor: 'pointer',
            flex: 0.8
        },
        iconStyle: {
            color: '#2F87FB',
            fontSize: 20,
            verticalAlign: 'middle'
        },
        actionsContainer: {
            width: 200,
            marginTop: 10,
            flex: 5,
            textAlign: 'right'
        },
        actionIcon: {
            fontSize: 25,
            verticalAlign: 'middle'
        },
        actionIconContainer: {
            border: 'solid 1px black',
            padding: 10,
            borderRadius: '50%',
            marginRight: 10,
            cursor: 'pointer'
        }
    }

    return  (
        <div style={styles.container}>
            <p style={styles.textLabel}>{textLabel}</p>
            { tagName && <span style={styles.tagLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>fork_right</span> {tagName}</span> }
            { username && <span style={styles.tagLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>person</span> {username}</span> }
            <div style={styles.actionsContainer}>
                <span onClick={onEdit} style={{ ...styles.actionIconContainer, borderColor: '#094392' }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: '#094392' }}>edit</span></span>
                <span onClick={onDelete} style={{ ...styles.actionIconContainer, borderColor: 'red' }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: 'red' }}>delete</span></span>
            </div>
        </div>
    );
}
