import NotificationComponent from '../Components/NotificationComponent';
import RiskSideMenu from '../Components/RiskSideMenu';
import { useState, useEffect } from 'react';
import { SendGet, getConnectedUser, isEmpty, SendPut } from '../State/Helper';
import { useNavigate } from 'react-router-dom';
import RiskTopMenu from "../Components/RiskTopMenu";

export default function NotificationScreen() {

    const [notificationsList, setNotificationsList] = useState([]);
    const connectedUser = getConnectedUser();
    const navigate = useNavigate();

    async function getNotificationsList() {

        const response = await SendGet(`api/v1/notifications/user/notopened/${connectedUser.id}`);
        if( !isEmpty(response.data) )
        {
            setNotificationsList(response.data);
        }
        else
        {
            setNotificationsList([]);
        }
    }

    async function openObject(notification) {

        if( notification.objectType === "risk" )
        {
            navigate(`/risks/detail/${notification.objectId}`);
        }
    }

    async function closeNotification(notification) {

        await SendPut(`api/v1/notifications/${notification.id}`, {
            ...notification,
            opened: 1
        });

        await getNotificationsList();
    }

    useEffect(() => {
        getNotificationsList();
    }, [])

    const styles = {
        mainContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        menuContainer: {
        },
        bodyContainer: {
            flex: 6,
            backgroundColor: '#FFFFFF',
            marginLeft: 20,
            marginRight: 10,
            padding: 20,
            paddingLeft: 30
        },
        mainTitle: {
            fontSize: 25,
            color: '#000000',
            fontWeight: 400
        }
    }

    return (

        <div>
            <RiskTopMenu />
            <div style={styles.mainContainer}>
                <div style={styles.menuContainer}>
                    <RiskSideMenu showSideMenu={true} selectedTab="notification" />
                </div>
                <div style={styles.bodyContainer}>
                    <p style={styles.mainTitle}>Liste notifications</p>
                    {
                        notificationsList.map((notification, index) => {
                            return (
                                <NotificationComponent key={index} notification={notification} openObject={() => openObject(notification)} closeNotification={() => closeNotification(notification)} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );

}
