import RiskSelect from "./RiskSelect";
import logoImage from "../assets/images/logo.svg";
import {useAuth} from "../State/useAuth";
import {useNavigate} from "react-router-dom";
import {useState, useEffect} from "react";
import {isEmpty, SendGet} from "../State/Helper";
import MinMenuItem from "./MinMenuItem";

export default function RiskTopMenu() {

    const [countries, setCountries] = useState([]);
    const [businessUnits, setBusinessUnits] = useState([]);
    const [mappings, setMappings] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [businessActivities, setBusinessActivities] = useState([]);
    const [showProfilMenu, setShowProfilMenu] = useState(false);
    const navigate = useNavigate();
    const { setCompany, setCountry, country, company, cartographie, setCartographie, businessUnit, setBusinessUnit, businessActivity, setBusinessActivity, expandSideMenu, setExpandSideMenu, logout, setActiveMenu, setTabSideMenu } = useAuth();

    const getCountries = async () => {

        const response = await SendGet(`api/v1/countries`);

        if( !isEmpty(response.data) )
        {
            setCountries(response.data);
        }
        else
        {
            setCountries([]);
        }

    }

    const getBusinessUnits = async () => {

        const response = await SendGet(`api/v1/bus`);

        if( !isEmpty(response.data) )
        {
            setBusinessUnits(response.data);
        }
        else
        {
            setBusinessUnits([]);
        }

    }

    const getBusinessActivities = async () => {

        const response = await SendGet(`api/v1/business-activities/bu/${businessUnit.value}`);

        if( !isEmpty(response.data) )
        {
            setBusinessActivities(response.data);
        }
        else
        {
            setBusinessActivities([]);
        }
    }

    const getCompanies = async () => {

        const response = await SendGet(`api/v1/companies`);

        if( !isEmpty(response.data) )
        {
            setCompanies(response.data);
        }
        else
        {
            setCompanies([]);
        }
    }

    const getMappings = async () => {

        const response = await SendGet(`api/v1/mappings`);

        if( !isEmpty(response.data) )
        {
            // Check for the user allowed permissions
            setMappings(response.data);
        }
        else
        {
            setMappings([]);
        }
    }

    useEffect(() => {
        async function fetch() {

            await getCountries();
            await getBusinessUnits();
            await getBusinessActivities();
            await getCompanies();
            await getMappings();
        }
        fetch().catch(e => console.log(`Error occurred: `, e));
    }, [businessUnit, country, company]);

    const handleCartographieChange = async (selectedOption) => {

        await setCartographie(selectedOption);

        await setBusinessUnit({ value: null, label: "Tout" });
        await setCountry({ value: null, label: "Tout" });
        await setCompany({ value: null, label: "Tout" });
        await setBusinessActivity({ value: null, label: "Tout" });

        await localStorage.setItem( 'businessUnit', `{"value":null,"label":"Tout"}` );
        await localStorage.setItem( 'country', `{"value":null,"label":"Tout"}` );
        await localStorage.setItem( 'company', `{"value":null,"label":"Tout"}` );
        await localStorage.setItem( 'businessActivity', `{"value":null,"label":"Tout"}` );

    };

    const handleBusinessUnitChange = (selectedOption) => {
        setBusinessUnit(selectedOption);

        setCountry({ value: null, label: "Tout" });
        setCompany({ value: null, label: "Tout" });
        setBusinessActivity({ value: null, label: "Tout" });
    };

    const handleCountryChange = (selectedOption) => {
        setCountry(selectedOption);

        setCompany({ value: null, label: "Tout" });
        setBusinessActivity({ value: null, label: "Tout" });
    };

    const handleCompanyChange = (selectedOption) => {
        setCompany(selectedOption);
        setBusinessActivity({ value: null, label: "Tout" });
    };

    const handleBusinessActivityChange = (selectedOption) => {
        setBusinessActivity(selectedOption);
    };

    const styles = {
        container: {
            display: 'flex',
            flex: 1,
            padding: 20,
            backgroundColor: '#FFFFFF'
        },
        selectContainer: {
            marginRight: 10,
            textAlign: 'center',
            flex: 7.0,
            height: 10,
        },
        logoContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: -10,
            marginRight: 20
        },
        selectLabel: {
            fontSize: 12,
            fontWeight: 700,
            color: '#023F96'
        },
        logoStyle: {
            width: 100,
            marginRight: 20,
            paddingTop: 5,
            cursor: 'pointer'
        },
        iconStyle: {
            fontSize: 15,
            marginRight: 5,
            verticalAlign: 'middle',
            color: '#023F96'
        },
        buttonIconStyle: {
            fontSize: 22,
            verticalAlign: 'middle',
            color: '#FFFFFF'
        },
        userPanelContainer: {
            display: 'flex',
            flexDirection: 'row',
            margin: 20,
            marginTop: 40,
            width: 140,
            flex: 1
        },
        panelButton: {
            padding:5,
            backgroundColor: '#62CFFC',
            borderRadius: 8,
            border: 'none',
            marginLeft: 5,
            cursor: 'pointer',
            width: 20,
            height: 20,
            justifyContent: 'center',
            alignItems: 'middle'
        },
        menuButton: {
            marginTop: 15,
            marginLeft: 0,
            border: 'none',
            borderRadius: 5,
            height: 25,
            cursor: 'pointer',
            backgroundColor: '#62CFFC',
            textAlign: 'center',
            width: 33
        },
        selectStyle: {
            fontSize: 12,
            borderRadius: 20,
            textAlign: 'left',
            fontWeight: 700,
            backgroundColor: '#FFFFFF',
            color: '#000000',
            minWidth: 130
        },
        inputField: {
            fontSize: 14,
            lineHeight: 2,
            borderRadius: 20,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        profilePicture: {
            width: 30,
            marginLeft: 20,
            borderRadius: 15,
            cursor: 'pointer'
        },
        profilContainer: {
            display: 'relative'
        },
        profilMenu: {
            position: 'absolute',
            width: 200,
            right: 20,
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1000,
            backgroundColor: '#FFFFFF',
            boxShadow: '2px 2px 2px lightgrey',
        },
        profilMenuItem: {
            margin: 0,
            padding: 10,
            cursor: 'pointer'
        },
        profileMenuItemIcon: {
            fontSize: 20,
            verticalAlign: 'middle'
        },
        profileMenuItemText: {
            fontSize: 14
        }
    }

    const setMenuState = () => {

        if( expandSideMenu === null )
        {
            setExpandSideMenu("user");
        }
        else
        {
            setExpandSideMenu(null);
        }

    }

    return (
        <div>
            
            <div style={styles.container}>
                <div style={{ marginLeft: -10, marginTop: -10, marginRight: 28 }}>
                    <MinMenuItem active={true} iconName="menu" label="" onClick={() => setMenuState()} />
                </div>
                <div style={styles.logoContainer}>
                    <img src={logoImage} style={styles.logoStyle} alt="logo" onClick={() => { navigate("/grid"); setActiveMenu("Grid"); setTabSideMenu("user"); }} />
                </div>
                <div style={styles.selectContainer}>
                    <RiskSelect data={mappings}  style={styles.selectStyle} value={cartographie} onChange={handleCartographieChange} itemName="linked_services" iconName="linked_services" />
                </div>
                <div style={styles.selectContainer}>
                    <RiskSelect data={businessUnits} style={styles.selectStyle} value={businessUnit} onChange={handleBusinessUnitChange} itemName="businessUnit" iconName="fork_left" />
                </div>
                <div style={styles.selectContainer}>
                    <RiskSelect useIcon={true} data={countries} style={styles.selectStyle} value={country} onChange={handleCountryChange} itemName="country" iconName="flag" />
                </div>
                <div style={styles.selectContainer}>
                    <RiskSelect data={companies} style={styles.selectStyle} value={company} onChange={handleCompanyChange} itemName="company" iconName="apartment" />
                </div>
                <div style={styles.selectContainer}>
                    <RiskSelect data={businessActivities} style={styles.selectStyle} value={businessActivity} onChange={handleBusinessActivityChange} itemName="businessActivity" iconName="corporate_fare" />
                </div>
                <div style={styles.profilContainer}>
                    <img src="https://www.shareicon.net/data/512x512/2016/05/24/770137_man_512x512.png"
                         style={styles.profilePicture}
                         onClick={() => setShowProfilMenu(!showProfilMenu)}
                         alt=""
                    />
                    {
                        showProfilMenu &&
                        <div style={styles.profilMenu}>
                            <div style={styles.profilMenuItem} className="btn-primary-custom" onClick={() => navigate('/users/profile')}>
                                <span style={styles.profileMenuItemIcon} className="material-symbols-outlined material-symbols-filled">person</span> <span style={styles.profileMenuItemText}>{JSON.parse(localStorage.getItem('user')).firstName} {JSON.parse(localStorage.getItem('user')).lastName}</span>
                            </div>
                            <div style={styles.profilMenuItem} className="btn-primary-custom" onClick={() => navigate('/notifications')}>
                                <span style={styles.profileMenuItemIcon} className="material-symbols-outlined material-symbols-filled">notifications</span> <span style={styles.profileMenuItemText}>Notifications</span>
                            </div>
                            <div style={styles.profilMenuItem} className="btn-primary-custom" onClick={logout}>
                                <span style={styles.profileMenuItemIcon} className="material-symbols-outlined material-symbols-filled">logout</span> <span style={styles.profileMenuItemText}>Déconnexion</span>
                            </div>
                        </div>
                    }
                </div>
            </div>    

        </div>
    );

}
