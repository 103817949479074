import RiskSideMenu from "../Components/RiskSideMenu";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Success from "../Components/Success";
import Error from "../Components/Error";
import { getConnectedUser, isEmpty, SendGet, SendPost, SendPut} from "../State/Helper";
import RiskTopMenu from "../Components/RiskTopMenu";

export default function GeneralSettings() {

    const { id } = useParams();
    const [companyLogo, setCompanyLogo] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [isEditMode, setIsEditMode] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();
    const connectedUser = getConnectedUser();

    const styles = {
        mainContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        menuContainer: {
        },
        container: {
            backgroundColor: '#FFFFFF',
            padding: 30,
            marginLeft: 20,
            borderRadius: 5,
            flex: 6
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        inputRow: {
            display: 'flex',
            flexDirection: 'column',
            padding: 10
        },
        inputField: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        iconStyle: {
            fontSize: 20,
            verticalAlign: 'middle'
        },
        colLabel: {
            fontSize: 14,
            textTransform: 'uppercase',
            color: '#000000'
        },
        mainButton: {
            color: '#000000',
            backgroundColor: '#FFFFFF',
            fontSize: 12,
            textTransform: 'uppercase',
            borderRadius: 5,
            padding: 4,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none',
            border: 'solid 1px #000000'
        },
        mainButtonIcon: {
            color: '#000000',
            verticalAlign: 'middle',
            fontSize: 20,
            fontWeight: 700
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 15,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        },
        requiredLabel: {
            color: '#D8111C'
        }
    }

    useEffect(() => {
        async function fetch() {
            if( !isEmpty(id) )
            {
                const response = await SendGet(`api/v1/general-settings/${id}`);

                if(response.data)
                {
                    setCompanyLogo(response.data.companyLogo);
                    setCompanyName(response.data.companyName);
                    setIsEditMode(true);
                }
            }
        }
        fetch().catch(e => console.log(`Error occurred during the getting of the general settings.`));
    }, []);

    async function updateSettings() {
        if( isEmpty(companyLogo) || isEmpty(companyName) )
        {
            setError(`Veuillez remplir tous le champs obligatoire.`);
            return;
        }

        await SendPut(`api/v1/general-settings/${id}`, {
            companyLogo: companyLogo,
            companyName: companyName
        });

        setSuccess(`Succès.`);
    }

    async function createSettings() {
        if( isEmpty(companyLogo) || isEmpty(companyName) )
        {
            setError(`Veuillez remplir tous le champs obligatoire.`);
            return;
        }

        await SendPost(`api/v1/general-settings`, {
            companyLogo: companyLogo,
            companyName: companyName,
            creatorId: connectedUser.id
        });

        setSuccess(`Succès.`);
    }

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.mainContainer}>
                <Success isVisible={success != null} sucessMessage={success} handleClose={() => setSuccess(null)} />
                <Error isVisible={error != null} errorMessage={error} handleClose={() => setError(null)} />
                <div style={styles.menuContainer}>
                    <RiskSideMenu />
                </div>
                <div style={styles.container}>

                    <div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Logo d'entreprise <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" value={companyLogo} onChange={e => setCompanyLogo(e.target.value)} style={styles.inputField} />
                        </div>
                        <div style={styles.inputRow}>
                            <span style={styles.colLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>label_important</span> Nom d'entreprise <span style={styles.requiredLabel}>*</span></span>
                            <input type="text" value={companyName} onChange={e => setCompanyName(e.target.value)} style={styles.inputField} />
                        </div>
                    </div>

                    <div style={{ textAlign: 'center', margin: 20 }}>
                        <span onClick={() => navigate("/list")} style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> Fermer</span>
                        { !isEditMode && <span onClick={() => createSettings()} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Créer</span> }
                        { isEditMode && <span style={styles.createBtn} onClick={() => updateSettings()}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> Enregistrer</span> }
                    </div>

                </div>
            </div>
        </div>
    );
}
