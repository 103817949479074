export default function ProbabilityLevel() {

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column'
        },
        row: {
            display: 'flex',
            flexDirection: 'row'
        },
        column: {
            flex: 2,
            margin: 5,
            borderRadius: 10,
            padding: 10
        },
        labelNumber: {
            fontSize: 14,
            fontWeight: 700,
            backgroundColor: '#FFFFFF',
            padding: 2,
            borderRadius: '50%'
        },
        labelTitle: {
            fontSize: 12,
            fontWeight: 700
        },
        labelPrice: {
            fontSize: 12,
            fontWeight: 700
        }
    }

    return (
        <div style={styles.container}>
            
            <div style={styles.row}>
                <div style={{ ...styles.column, backgroundColor: '#FFE6E6', border: 'solid 1px #985353' }}>
                    <span style={{ ...styles.labelNumber, color: '#985353', marginBottom: 15 }}>6</span> <br />
                    <span style={{ ...styles.labelTitle, color: '#985353' }}>VERY PROBABLE</span> <br />
                    <span style={{ ...styles.labelPrice, color: '#985353' }}>May occur several times a month.</span>
                </div>
                <div style={{ ...styles.column, backgroundColor: '#FFEBE8', border: 'solid 1px #C27166' }}>
                    <span style={{ ...styles.labelNumber, color: '#C27166' }}>5</span> <br />
                    <span style={{ ...styles.labelTitle, color: '#C27166' }}>PROBABLE</span> <br />
                    <span style={{ ...styles.labelPrice, color: '#C27166' }}>May occur several times a year.</span>
                </div>
                <div style={{ ...styles.column, backgroundColor: '#FFF1E8', border: 'solid 1px #9E542E' }}>
                    <span style={{ ...styles.labelNumber, color: '#9E542E' }}>4</span> <br />
                    <span style={{ ...styles.labelTitle, color: '#9E542E' }}>IMPROBABLE</span> <br />
                    <span style={{ ...styles.labelPrice, color: '#9E542E' }}>May occur once every 2 years.</span>
                </div>
            </div>

            <div style={styles.row}>
                <div style={{ ...styles.column, backgroundColor: '#FEFAF0', border: 'solid 1px #8B7435' }}>
                    <span style={{ ...styles.labelNumber, color: '#8B7435' }}>3</span> <br />
                    <span style={{ ...styles.labelTitle, color: '#8B7435' }}>VERY IMPROBABLE</span> <br />
                    <span style={{ ...styles.labelPrice, color: '#8B7435' }}>May occur once every 5-10 years.</span>
                </div>
                <div style={{ ...styles.column, backgroundColor: '#F8FFF3', border: 'solid 1px #6CA943' }}>
                    <span style={{ ...styles.labelNumber, color: '#6CA943' }}>2</span> <br />
                    <span style={{ ...styles.labelTitle, color: '#6CA943' }}>EXTREMLY IMPROBABLE</span> <br />
                    <span style={{ ...styles.labelPrice, color: '#6CA943' }}>Has already occurred exceptionally in the Group or in the industry.</span>
                </div>
                <div style={{ ...styles.column, backgroundColor: '#E9F8EF', border: 'solid 1px #72B68C' }}>
                    <span style={{ ...styles.labelNumber, color: '#72B68C' }}>1</span> <br />
                    <span style={{ ...styles.labelTitle, color: '#72B68C' }}>ISOLATED CASE</span> <br />
                    <span style={{ ...styles.labelPrice, color: '#72B68C' }}>Possible event but never happend.</span>
                </div>
            </div>

        </div>
    );
}