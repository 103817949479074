import {useNavigate, useParams} from "react-router-dom";
import RiskSideMenu from "../Components/RiskSideMenu";
import frenchLogo from "../assets/images/french_logo.png";
import britainLogo from "../assets/images/britain.png";
import {useState, useEffect} from "react";
import {getConnectedUser, isEmpty, SendDelete, SendGet, SendPost, SendPut} from "../State/Helper";
import Success from "../Components/Success";
import Error from "../Components/Error";
import RiskTopMenu from "../Components/RiskTopMenu";
import { primaryColor } from "../configuration/colorPalet";

export default function Mapping() {

    const navigate = useNavigate();
    const { id } = useParams();
    const [themes, setThemes] = useState([]);
    const [icons] = useState([
        {id: 1, name: '3d_rotation'},
        {id: 2, name: 'ac_unit'},
        {id: 3, name: 'access_alarm'},
        {id: 4, name: 'access_alarms'},
        {id: 5, name: 'access_time'},
        {id: 6, name: 'accessibility'},
        {id: 7, name: 'accessible'},
        {id: 8, name: 'account_balance'},
        {id: 9, name: 'account_balance_wallet'},
        {id: 10, name: 'account_box'},
        {id: 11, name: 'account_circle'},
        {id: 12, name: 'adb'},
        {id: 13, name: 'add'},
        {id: 14, name: 'add_a_photo'},
        {id: 15, name: 'add_alarm'},
        {id: 16, name: 'add_alert'},
        {id: 17, name: 'add_box'},
        {id: 18, name: 'add_circle'},
        {id: 19, name: 'add_circle_outline'},
        {id: 20, name: 'add_location'},
        {id: 21, name: 'add_shopping_cart'},
        {id: 22, name: 'add_to_photos'},
        {id: 23, name: 'add_to_queue'},
        {id: 24, name: 'adjust'},
        {id: 25, name: 'airline_seat_flat'},
        {id: 26, name: 'airline_seat_flat_angled'},
        {id: 27, name: 'airline_seat_individual_suite'},
        {id: 28, name: 'airline_seat_legroom_extra'},
        {id: 29, name: 'airline_seat_legroom_normal'},
        {id: 30, name: 'airline_seat_legroom_reduced'},
        {id: 31, name: 'airline_seat_recline_extra'},
        {id: 32, name: 'airline_seat_recline_normal'},
        {id: 33, name: 'airplanemode_active'},
        {id: 34, name: 'airplanemode_inactive'},
        {id: 35, name: 'airplay'},
        {id: 36, name: 'airport_shuttle'},
        {id: 37, name: 'alarm'},
        {id: 38, name: 'alarm_add'},
        {id: 39, name: 'alarm_off'},
        {id: 40, name: 'alarm_on'},
        {id: 41, name: 'album'},
        {id: 42, name: 'all_inclusive'},
        {id: 43, name: 'all_out'},
        {id: 44, name: 'android'},
        {id: 45, name: 'announcement'},
        {id: 46, name: 'apps'},
        {id: 47, name: 'archive'},
        {id: 48, name: 'arrow_back'},
        {id: 49, name: 'arrow_downward'},
        {id: 50, name: 'arrow_drop_down'},
        {id: 51, name: 'arrow_drop_down_circle'},
        {id: 52, name: 'arrow_drop_up'},
        {id: 53, name: 'arrow_forward'},
        {id: 54, name: 'arrow_upward'},
        {id: 55, name: 'art_track'},
        {id: 56, name: 'aspect_ratio'},
        {id: 57, name: 'assessment'},
        {id: 58, name: 'assignment'},
        {id: 59, name: 'assignment_ind'},
        {id: 60, name: 'assignment_late'},
        {id: 61, name: 'assignment_return'},
        {id: 62, name: 'assignment_returned'},
        {id: 63, name: 'assignment_turned_in'},
        {id: 64, name: 'assistant'},
        {id: 65, name: 'assistant_photo'},
        {id: 66, name: 'attach_file'},
        {id: 67, name: 'attach_money'},
        {id: 68, name: 'attachment'},
        {id: 69, name: 'audiotrack'}]
    );
    const [showIcons, setShowIcons] = useState(false);
    const [consequences, setConsequences] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [error, setError] = useState(null);
    const [name, setName] = useState("");
    const [success, setSuccess] = useState(null);
    const connectedUser = getConnectedUser();
    const [mapping, setMapping] = useState();
    const [frTheme, setFrTheme] = useState("");
    const [enTheme, setEnTheme] = useState("");
    const [frConsequence, setFrConsequence] = useState("");
    const [enConsequence, setEnConsequence] = useState("");
    const [selectedIcon, setSelectedIcon] = useState("");

    const styles = {
        mainContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        sideMenuContainer: {
        },
        contentContainer: {
            flex: 5,
            display: 'flex',
            flexDirection: 'column',
            padding: 20
        },
        headerContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        primaryLabel: {
            fontSize: 28,
            color: primaryColor,
            marginLeft: 20,
            flex: 1
        },
        iconStyle: {
            fontSize: 20,
            fontWeight: 700,
            verticalAlign: 'middle'
        },
        formContainer: {
            backgroundColor: '#FFFFFF',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
            paddingLeft: 40,
            paddingRight: 40,
            margin: 40,
            border: 'solid 1px #E2E2E2'
        },
        labelRequired: {
            color: '#D70C17',
            fontSize: 12,
            fontWeight: 600
        },
        seperator: {
            backgroundColor: '#E2E2E2',
            height: 1,
            marginTop: 40,
            marginBottom: 40
        },
        textLabel: {
            fontSize: 14,
            textTransform: 'uppercase',
            color: '#000000'
        },
        flagIcon: {
            width: 20,
            height: 15,
            marginRight: 5,
            borderRadius: 5,
            verticalAlign: 'middle'
        },
        inputField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 5,
            border: 'solid 1px #E2E2E2',
            paddingLeft: 15,
            color: '#000000',
            marginTop: 10
        },
        inputContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 20
        },
        primaryButton: {
            color: '#000000',
            fontSize: 12,
            textTransform: 'uppercase',
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 7,
            width: 180,
            alignSelf: 'center',
            borderRadius: 20,
            backgroundColor: '#FFFFFF',
            cursor: 'pointer',
            border: 'solid 1px #E2E2E2',
        },
        themeContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row'
        },
        themeContent: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            maxWidth: 200,
            minWidth: 150,
            backgroundColor: '#FFFFFF',
            padding: 15,
            borderRadius: 10,
            border: 'solid 1px #E2E2E2',
            margin: 10
        },
        themeLabelContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        themeButtonContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        themMainLabel: {
            fontSize: 14,
            fontWeight: 600,
            color: '#000000'
        },
        themeSecondaryLabel: {
            fontSize: 12,
            fontWeight: 600,
            color: '#767676'
        },
        themeDeleteButton: {
            color: '#FFFFFF',
            fontSize: 20,
            backgroundColor: '#D70C17',
            padding: 5,
            borderRadius: '50%',
            marginLeft: 15,
            cursor: 'pointer'
        },
        iconsContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        iconContainer: {
            flex: '*',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row'
        },
        themeIcon: {
            fontSize: 20,
            color: '#000000'
        },
        createBtn: {
            backgroundColor: '#2F87FB',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        },
        cancelBtn: {
            backgroundColor: '#D70C17',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontSize: 12,
            border: 'none',
            borderRadius: 5,
            margin: 5,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: 700,
            paddingTop: 8,
            paddingBottom: 10,
            height: 35
        },
        btnContainer: {
            display: 'flex',
            flexDirection: 'row',
            margin: 40,
            justifyContent: 'center'
        },
        selectField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#275B9F',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        }
    }

    // Check if in edit mode or create mode
    useEffect(() => {
        async function fetch() {
            if( !isEmpty(id) )
            {
                const response = await SendGet(`api/v1/mappings/${id}`);
                if(response.data)
                {
                    setName(response.data.name);
                    setMapping(response.data);
                    setIsEditMode(true);
                }
            }
        }
        fetch();
    }, []);

    const getThemes = async () => {
        try
        {
            const response = await SendGet(`api/v1/themes/mapping/${id}`);
            setThemes(response.data);
        }
        catch(Exception)
        {
            console.log(Exception);
        }
    }

    const getConsequences = async () => {
        try
        {
            const response = await SendGet(`api/v1/potentialConsequences/mapping/${id}`);
            setConsequences(response.data);
        }
        catch(Exception)
        {
            console.log(Exception);
        }
    }

    const createMapping = async () => {
        try
        {
            if( name === '' )
            {
                setError('Please fill all the required fields!');
                return false;
            }

            // Create new cartographie
            await SendPost('api/v1/mappings', {
                'name': name,
                'creatorId': connectedUser.id
            });

            navigate(`/mappings`);
        }
        catch(Exception)
        {
            setError('Error occurred during the action!');
        }
    }

    const updateMapping = async () => {
        try
        {
            if( name === '' )
            {
                setError('Please fill all the required fields!');
                return false;
            }

            // Update the mapping
            await SendPut(`api/v1/mappings/${id}`, {
                'name': name,
                'creatorId': mapping.creatorId
            });

            setError(null);
            setSuccess('Mapping updated successfully!');
        }
        catch(Exception)
        {
            setError('Error occurred during the action!');
        }
    }

    const createTheme = async () => {
        try
        {
            if( frTheme === '' || enTheme === '' )
            {
                setError('Please fill all the required fields!');
                return false;
            }

            await SendPost('api/v1/themes', {
                'frTitle': frTheme,
                'enTitle': enTheme,
                'mappingId': id,
                'creatorId': connectedUser.id
            });

            await getThemes();
        }
        catch(Exception)
        {
            setError('Error occurred during the action!');
        }
    }

    const createConsequence = async () => {
        try
        {
            if( frConsequence === '' || enConsequence === '' || selectedIcon === '' )
            {
                setError('Please fill all the required fields!');
                return false;
            }

            await SendPost('api/v1/potentialConsequences', {
                'frTitle': frConsequence,
                'enTitle': enConsequence,
                'mappingId': id,
                'creatorId': connectedUser.id,
                'icon': selectedIcon
            });

            await getConsequences();
        }
        catch(Exception)
        {
            setError('Error occurred during the action!');
        }
    }

    const deleteTheme = async (themeId) => {
        try
        {

            if( window.confirm('Do you want to delete the mapping?') )
            {
                await SendDelete(`api/v1/themes/${themeId}`);
                await getThemes();
            }
        }
        catch(Exception)
        {
            console.log(Exception);
        }
    }

    const deleteConsequence = async (consequenceId) => {
        try
        {

            if( window.confirm('Do you want to delete the consequence?') )
            {
                await SendDelete(`api/v1/potentialConsequences/${consequenceId}`);
                await getConsequences();
            }
        }
        catch(Exception)
        {
            console.log(Exception);
        }
    }

    useEffect(() => {
        async function fetch() {
            await getThemes();
            await getConsequences();
        }
        fetch();
    }, []);

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.mainContainer}>
                <div style={styles.sideMenuContainer}>
                    <RiskSideMenu showSideMenu={true} />
                </div>
                <div style={styles.contentContainer}>
                    <div style={styles.headerContainer}>
                        <span style={styles.primaryLabel}>Cartographie</span>
                    </div>
                    <div style={styles.formContainer}>

                        <Success isVisible={success != null} sucessMessage={success} handleClose={() => setSuccess(null)} />
                        <Error isVisible={error != null} errorMessage={error} handleClose={() => setError(null)} />

                        <p style={styles.labelRequired}>* Champs obligatoires</p>
                        <div style={{ ...styles.inputContainer, marginBottom: -10, marginTop: 5 }}>
                            <label style={styles.textLabel}> <span className="material-symbols-outlined" style={styles.iconStyle}>swap_vert</span> Nom <span style={styles.labelRequired}>*</span></label>
                            <input type="text" style={styles.inputField} value={name} onChange={e => setName(e.target.value)} />
                        </div>
                        {
                            isEditMode &&
                            <>
                                <div style={styles.seperator}></div>
                                <p style={styles.textLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>bookmark</span> THEMES <span style={styles.labelRequired}>*</span></p>
                                <div style={styles.inputContainer}>
                                    <label style={styles.textLabel}><img src={frenchLogo} style={styles.flagIcon}  alt="french"/> <span className="material-symbols-outlined" style={styles.iconStyle}>swap_vert</span> Titre francais</label>
                                    <input type="text" style={styles.inputField} value={frTheme} onChange={e => setFrTheme(e.target.value)} />
                                </div>
                                <div style={styles.inputContainer}>
                                    <label style={styles.textLabel}><img src={britainLogo} style={styles.flagIcon}  alt="french"/> <span className="material-symbols-outlined" style={styles.iconStyle}>swap_vert</span> Titre anglais</label>
                                    <input type="text" style={styles.inputField} value={enTheme} onChange={e => setEnTheme(e.target.value)} />
                                </div>
                                <div style={styles.inputContainer}>
                                    { isEditMode && <button onClick={createTheme} style={{ ...styles.primaryButton, width: 200 }}><span className="material-symbols-outlined" style={styles.iconStyle}>add</span> Ajouter ce thème</button> }
                                </div>
                                <div style={styles.themeContainer}>
                                    {
                                        themes && themes.map(item => {
                                            return (
                                                <div key={item.id} style={styles.themeContent}>
                                                    <div style={styles.themeLabelContainer}>
                                                        <span style={styles.themMainLabel}>{item.frTitle}</span>
                                                        <span style={styles.themeSecondaryLabel}>{item.enTitle}</span>
                                                    </div>
                                                    <div style={styles.themeButtonContainer}>
                                                        <span onClick={() => deleteTheme(item.id)} className="material-symbols-outlined" style={styles.themeDeleteButton}>delete</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        }

                        <div style={styles.seperator}></div>

                        { isEditMode &&
                            <>
                                <p style={styles.textLabel}><span className="material-symbols-outlined" style={styles.iconStyle}>local_fire_department</span> Les conséquences potentielles <span style={styles.labelRequired}>*</span></p>
                                <div style={styles.inputContainer}>
                                    <label style={styles.textLabel}><img src={frenchLogo} style={styles.flagIcon}  alt="french"/> <span className="material-symbols-outlined" style={styles.iconStyle}>swap_vert</span> Titre francais</label>
                                    <input type="text" style={styles.inputField} value={frConsequence} onChange={e => setFrConsequence(e.target.value)} />
                                </div>
                                <div style={styles.inputContainer}>
                                    <label style={styles.textLabel}><img src={britainLogo} style={styles.flagIcon}  alt="french"/> <span className="material-symbols-outlined" style={styles.iconStyle}>swap_vert</span> Titre anglais</label>
                                    <input type="text" style={styles.inputField} value={enConsequence} onChange={e => setEnConsequence(e.target.value)} />
                                </div>
                                <div style={styles.inputContainer}>
                                    <button style={{ ...styles.primaryButton, width: 250, backgroundColor: '#FFFFFF' }} onClick={() => setShowIcons(!showIcons)}>Choisissez une icône
                                        { !showIcons && <span className="material-symbols-outlined" style={styles.iconStyle}>expand_more</span> }
                                        { showIcons && <span className="material-symbols-outlined" style={styles.iconStyle}>expand_less</span> }
                                    </button>
                                </div>
                                {
                                    showIcons &&
                                    <div style={styles.iconsContainer}>
                                        {
                                            icons.map(icon => {
                                                return (
                                                    <div key={icon.id} style={styles.iconContainer}>
                                                        <input data-iconname={icon.name} type="radio" name="theme_icon" onChange={e => setSelectedIcon(e.target.dataset.iconname)} /> <span className="material-symbols-outlined" style={styles.themeIcon}>{icon.name}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                <div style={styles.inputContainer}>
                                    <button style={{ ...styles.primaryButton, width: 370, marginTop: 10 }} onClick={createConsequence}><span className="material-symbols-outlined" style={styles.iconStyle}>add</span> Ajoutez cette conséquence potentielle</button>
                                </div>
                            </>
                        }


                        <div style={styles.themeContainer}>
                            {
                                consequences && consequences.map(item => {
                                    return (
                                        <div key={item.id} style={styles.themeContent}>
                                            <div>
                                                <span className="material-symbols-outlined" style={{ ...styles.iconStyle, color: '#000000', marginRight: 20 }}>{item.icon}</span>
                                            </div>
                                            <div style={styles.themeLabelContainer}>
                                                <span style={styles.themMainLabel}>{item.frTitle}</span>
                                                <span style={styles.themeSecondaryLabel}>{item.enTitle}</span>
                                            </div>
                                            <div style={styles.themeButtonContainer}>
                                                <span onClick={() => deleteConsequence(item.id)} className="material-symbols-outlined" style={styles.themeDeleteButton}>delete</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={styles.btnContainer}>
                            <div style={{ textAlign: 'center', marginRight: 20 }}>
                                <button onClick={() => navigate("/mappings")} style={styles.cancelBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>close</span> Fermer</button>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                { isEditMode && <button onClick={updateMapping} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> MODIFIER</button> }
                                { !isEditMode && <button onClick={createMapping} style={styles.createBtn}><span className="material-symbols-outlined" style={styles.iconStyle}>check</span> créer</button> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
