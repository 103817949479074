import RiskSideMenu from "../Components/RiskSideMenu";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {SendDelete, SendGet} from "../State/Helper";

export default function ThemesList() {
    
    const navigate = useNavigate();
    const [themes, setThemes] = useState([]);

    const styles = {
        contentContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        mainSectionContainer: {
            margin: 20,
            marginLeft: 20,
            flex: 1
        },
        mainTitle: {
            fontSize: 34,
            color: '#67D0FA',
            fontWeight: 400,
        },
        mainButton: {
            color: '#FFFFFF',
            backgroundColor: '#2F87FB',
            fontSize: 13,
            padding: '5px 20px 5px 20px',
            textTransform: 'uppercase',
            borderRadius: 15,
            cursor: 'pointer',
            float: 'right',
            fontWeight: 600,
            textDecoration: 'none'
        },
        mainButtonIcon: {
            color: '#FFFFFF',
            verticalAlign: 'middle'
        },
        cardContainer: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            padding: 20,
            marginBottom: 20,
            borderRadius: 10
        },
        mainContainer: {
            marginTop: 20
        },
        flagIcon: {
            width: 50,
            height: 30,
            verticalAlign: 'middle',
            marginRight: 10
        },
        countryLabel: {
            color: '#0943B5',
            fontWeight: 600
        },
        actionIcon: {
            fontSize: 25,
            verticalAlign: 'middle'
        },
        actionIconContainer: {
            border: 'solid 1px #0943B5',
            padding: 8,
            borderRadius: '60%',
            marginRight: 10,
        }
    }

    const getThemes = async () => {
        try
        {
            const response = await SendGet('api/v1/themes');
            setThemes(response.data);
        }
        catch(Error)
        {
            console.log(Error);
        }
    }

    const deleteTheme = async (id) => {

        if(window.confirm('Do you want to delete the theme?'))
        {
            try
            {
                await SendDelete(`api/v1/themes/${id}`);
                await getThemes();
            }
            catch(error)
            {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        getThemes();
    }, []);
    
    return (
        <div>
            <div style={styles.contentContainer}>
                <RiskSideMenu showSideMenu={true} selectedTab="" />
                <div style={styles.mainSectionContainer}>
                    <div>
                        <span style={styles.mainTitle}>Gestion des themes</span>
                        <span onClick={() => navigate("/theme/create")} style={styles.mainButton}><span className="material-symbols-outlined" style={styles.mainButtonIcon}>add</span> Ajouter</span>
                    </div>
                    <div style={styles.mainContainer}>
                        {
                            themes && themes.map(item => {
                                return (
                                    <div key={item.id} style={styles.cardContainer}>
                                        <div style={{ flex: 1 }}>
                                            <span style={styles.countryLabel}>{item.frTitle}</span>
                                        </div>
                                        <div style={{ float: 'right' }}>
                                            <Link to={`/theme/edit/${item.id}`} style={{ ...styles.actionIconContainer, borderColor: '#094392', marginTop: 20 }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: '#094392' }}>edit</span></Link>
                                            <span onClick={() => deleteTheme(item.id)} style={{ ...styles.actionIconContainer, cursor: 'pointer', borderColor: 'red', marginTop: 20 }}><span className="material-symbols-outlined" style={{ ...styles.actionIcon, color: 'red' }}>delete</span></span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}