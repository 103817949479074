import RiskSideMenu from "../Components/RiskSideMenu";
import { useState, useEffect } from "react";
import { SendGet, isEmpty } from "../State/Helper";
import { useAuth } from "../State/useAuth";
import RiskTopMenu from "../Components/RiskTopMenu";
import { primaryColor } from "../configuration/colorPalet";

export default function Statistics()
{
    const [showDetails, setShowDetails] = useState(false);
    const [risksList, setRisksList] = useState([]);

    const [crticialRisks, setCriticalRisks] = useState(0);
    const [mediumRisks, setMediumRisks] = useState(0);
    const [lowRisks, setLowRisks] = useState(0);
    const [societeList, setSocieteList] = useState([]);
    const { mappingId } = useAuth();
    const [mappings, setMappings] = useState([]);

    async function fetchData() {

        // Fetch assessments
        let criticalRisksCounter = 0;
        let mediumRisksCounter = 0;
        let lowRisksCounter = 0;
        let risks = [];

        try
        {
            // Fetch companies
            const companyResponse = await SendGet(`api/v1/companies`);
            setSocieteList(companyResponse.data);

            // Fetch risks
            const riskResponse = await SendGet(`api/v1/risks`);

            if ( riskResponse.data !== undefined && Array.isArray(riskResponse.data) )
            {
                risks = riskResponse.data;

                for (let item of risks)
                {
                    try
                    {
                        // Get assessments list with counting based on status
                        let response = await SendGet(`api/v1/assessments/risk/${item.id}`);

                        if( response.data !== undefined && Array.isArray(response.data) && response.data.length > 0 )
                        {
                            let activeAssessment = response.data.reduce((max, obj) => parseInt(obj.version) > parseInt(max.version) ? obj : max);

                            if (activeAssessment.riskBruteNormalise >= 5)
                            {
                                criticalRisksCounter += 1;
                            }
                            else if (activeAssessment.riskBruteNormalise >= 3)
                            {
                                mediumRisksCounter += 1;
                            }
                            else
                            {
                                lowRisksCounter += 1;
                            }
                        }

                        // Get actions list with counting based on status
                        let actionsResponse = await SendGet(`api/v1/actions/risk/${item.id}`);
                        if( actionsResponse.data !== undefined && Array.isArray(actionsResponse.data) )
                        {
                            item.actions = actionsResponse.data;
                        }
                        else
                        {
                            item.actions = [];
                        }

                    } catch (error)
                    {
                        console.error('Error fetching assessment', error);
                    }
                }
            }

            // Set variables to the state
            setRisksList(risks);
            setCriticalRisks(criticalRisksCounter);
            setMediumRisks(mediumRisksCounter);
            setLowRisks(lowRisksCounter);
        }
        catch(error)
        {
            console.log(error);
        }
    }

    async function getMappings() {

        let mappings = [];

        for(let risk of risksList)
        {
            if( !mappings.includes( parseInt(risk.mappingId) ) )
            {
                mappings.push(risk.mappingId);
            }
        }

        setMappings(mappings);
    }

    function getCompletionPercentage(companyId)
    {
        let statistics = [];

        for (let risk of risksList)
        {
            if (risk.companyId === companyId)
            {
                let completedActions = 0;
                let leftActions = 0;

                for (let action of risk.actions)
                {
                    if (String(action.status).toLowerCase() === 'completed')
                    {
                        completedActions++;
                    }
                    else
                    {
                        leftActions++;
                    }
                }

                // Find the company object in societeList based on companyId
                let company = societeList.find(company => company.id === companyId);

                statistics.push({
                    company: company, // Replace companyId with the entire company object
                    completedActions: completedActions,
                    leftActions: leftActions
                });
            }
        }

        // Remove duplicates based on companyId
        let new_statistics = statistics.filter((obj, index, self) =>
            index === self.findIndex((o) => o.company.id === obj.company.id)
        );

        if( new_statistics.length > 0 )
        {
            return new_statistics[0];
        }
        else
        {
            /*return {
                company: companyId,
                completedActions: 0,
                leftActions: 0
            };*/

            return null;
        }
    }

    useEffect(() => {
        async function fetch() {
            await fetchData();
            await getMappings();
        }
        fetch();
    }, []);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row'
        },
        sideMenuContainer: {

        },
        contentContainer: {
            flex: 5,
            padding: 20
        },
        headerContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        mainTitle: {
            fontSize: 32,
            color: primaryColor,
            flex: 1,
            paddingLeft: 20,
            marginLeft: 20
        },
        filterContainer: {
            display: 'grid',
            flex: 2
        },
        filterCol: {
            display: 'flex',
            flex: 2,
            flexDirection: 'column',
            textAlign: 'center'
        },
        inputField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#275B9F',
            marginTop: 10
        },
        selectField: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 2.5,
            borderRadius: 20,
            border: 'solid 1px #D6D7D9',
            paddingLeft: 15,
            color: '#275B9F',
            marginTop: 10,
            backgroundColor: '#FFFFFF',
            height: 40
        },
        bodyContainer: {
            backgroundColor: '#FFFFFF',
            margin: 20,
            borderRadius: 10,
            padding: 30
        },
        secondaryTitle: {
            fontSize: 20,
            fontWeight: 700,
            color: '#000000'
        },
        bodyHeader: {
            marginTop: 20,
            backgroundColor: '#FFFFFF',
            padding: 20,
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 10,
            border: 'solid 1px #E2E2E2'
        }
    }

    return (
        <div>
            <RiskTopMenu />
            <div style={styles.container}>
                <div style={styles.sideMenuContainer}>
                    <RiskSideMenu />
                </div>
                <div style={styles.contentContainer}>
                    <div style={styles.headerContainer}>
                        <span style={styles.mainTitle}>Statistiques</span>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, backgroundColor: '#FFFFFF', padding: 20, borderRadius: 5, border: 'solid 1px #E2E2E2', marginRight: 20 }}>
                            <div style={{ flex: 0.8, display: 'flex', flexDirection: 'column', alignSelf: 'center', color: '#000000' }}>
                                <span style={{ fontSize: 12, textAlign: 'center' }}>FILTRER:</span>
                            </div>
                            <div style={{ flex: 2, display: 'flex', flexDirection: 'column', marginRight: 10, textAlign: 'center' }}>
                                <span style={{ color: '#000000', fontSize: 12 }}><span className="material-symbols-outlined" style={{ fontSize: 20, cursor: 'pointer', verticalAlign: 'middle' }}>person</span> CARTOGRAPHIE</span>
                                <select style={{ ...styles.selectField, backgroundColor: '#767676', color: '#FFFFFF', border: 'none' }}>
                                    <option value="Juridique">Juridique</option>
                                </select>
                            </div>
                            <div style={{ flex:2, display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <span style={{ color: '#000000', fontSize: 12 }}><span className="material-symbols-outlined" style={{ fontSize: 20, cursor: 'pointer', verticalAlign: 'middle' }}>fork_right</span> {String(`Business Unit`).toUpperCase()}</span>
                                <select style={{ ...styles.selectField, backgroundColor: '#767676', color: '#FFFFFF', border: 'none' }}>
                                    <option value="Irrigation">Irrigation</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style={styles.bodyContainer}>
                        <p style={styles.secondaryTitle}>Performance et trafic</p>
                        <div style={styles.bodyHeader}>
                            <div style={{ flex: 2, textAlign: 'center' }}>
                                <span style={{ fontSize: 12, color: '#000000', fontWeight: 500 }}><span className="material-symbols-outlined" style={{ fontSize: 20, cursor: 'pointer', verticalAlign: 'middle' }}>globe</span> CENTRE DE PROFIT</span>
                            </div>
                            <div style={{ flex: 2, textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                                <span style={{ fontSize: 12, color: '#000000', fontWeight: 500, textTransform: 'uppercase', marginBottom: 15 }}><span className="material-symbols-outlined" style={{ fontSize: 20, cursor: 'pointer', verticalAlign: 'middle' }}>circle</span> état d'avancement</span>
                                <div>
                                    <span style={{ fontSize: 12, margin: 10, fontWeight: 600, color: '#D70C17' }}><span className="material-symbols-outlined" style={{ fontSize: 15, cursor: 'pointer', verticalAlign: 'middle' }}>trip_origin</span> Critic</span>
                                    <span style={{ fontSize: 12, margin: 10, fontWeight: 600, color: '#FC7A49' }}><span className="material-symbols-outlined" style={{ fontSize: 15, cursor: 'pointer', verticalAlign: 'middle' }}>trip_origin</span> Medium</span>
                                    <span style={{ fontSize: 12, margin: 10, fontWeight: 600, color: '#34B764' }}><span className="material-symbols-outlined" style={{ fontSize: 15, cursor: 'pointer', verticalAlign: 'middle' }}>trip_origin</span> Low</span>
                                </div>
                            </div>
                            <div style={{ flex: 2, textAlign: 'center' }}>
                                <span style={{ fontSize: 12, color: '#000000', fontWeight: 500 }}><span className="material-symbols-outlined" style={{ fontSize: 20, cursor: 'pointer', verticalAlign: 'middle' }}>bar_chart</span> Trafic <span className="material-symbols-outlined" style={{ fontSize: 20, cursor: 'pointer', verticalAlign: 'middle', color: '#000000' }}>info</span></span>
                            </div>
                        </div>
                        {
                            !isEmpty(risksList) &&

                            <div onClick={() => { getCompletionPercentage(mappingId); setShowDetails(!showDetails) }} style={{ backgroundColor: '#FFFFFF', border: 'solid 1px #E2E2E2', borderRadius: '20px 20px 0px 0px', display: 'flex', flexDirection: 'row', marginTop: 20, cursor: 'pointer' }}>
                                <div style={{ flex: 1.5, borderRight: 'solid 1px #E2E2E2', padding: 20 }}>
                                    <span onClick={() => getCompletionPercentage(1)} style={{ backgroundColor: '#FFFFFF', color: '#000000', padding: 4, borderRadius: 5, cursor: 'pointer' }}>AFR</span>
                                </div>
                                <div style={{ flex: 3, borderRight: 'solid 1px #E2E2E2', padding: 20, display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
                                    <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 18, fontWeight: 600, color: '#D70C17' }}>{ Math.round((crticialRisks / risksList.length) * 100) }%</span>
                                        <span style={{ fontSize: 12, flexDirection: 'column', marginTop: 10, color: '#D70C17' }}>{ crticialRisks } sur {societeList.length} sociétés</span>
                                    </div>
                                    <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 18, fontWeight: 600, color: '#FC7A49' }}>{ Math.round((mediumRisks / risksList.length) * 100) }%</span>
                                        <span style={{ fontSize: 12, marginTop: 10, color: '#FC7A49' }}>{mediumRisks} sur {societeList.length} sociétés</span>
                                    </div>
                                    <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 18, fontWeight: 600, color: '#34B764' }}>{ Math.round((lowRisks / risksList.length) * 100) }%</span>
                                        <span style={{ fontSize: 12, marginTop: 10, color: '#34B764' }}>{lowRisks} sur {societeList.length} sociétés</span>
                                    </div>
                                </div>
                                <div style={{ flex: 1.5, padding: 20 }}>
                                    <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: 18, fontWeight: 600, color: '#767676' }}>100%</span>
                                        <span style={{ fontSize: 12, marginTop: 10, color: '#767676' }}>{risksList.length} sur {societeList.length}</span>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            showDetails &&

                            <div style={{ border: 'solid 1px #E2E2E2', borderRadius: '0px 0px 20px 0px', display: 'flex', flexDirection: 'column', padding: 10, marginBottom: 10 }}>
                                <p style={{ fontSize: 14, color: '#000000', fontWeight: 700 }}><span className="material-symbols-outlined" style={{ fontSize: 20, cursor: 'pointer', verticalAlign: 'middle', fontWeight: 700 }}>House</span> Détails par société</p>
                                <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingBottom: 20 }}>
                                    {
                                        Array.isArray(societeList) && societeList.map(societe => {

                                            const societeData = getCompletionPercentage(societe.id);

                                            if( societeData != null )
                                            {
                                                return (
                                                    <div key={societe.id} style={{ backgroundColor: societeData.leftActions > societeData.completedActions ? 'red' : '#34B764', color: '#FFFFFF', padding: 10, paddingTop: 0, margin: 10 }}>
                                                        <p style={{ fontSize: 12, fontWeight: 700 }}>{ societe.name }</p>
                                                        <span style={{ fontSize: 12, backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: 6, marginRight: 10, borderRadius: 5 }}><span className="material-symbols-outlined" style={{ fontSize: 20, cursor: 'pointer', verticalAlign: 'middle' }}>check_box</span> Complétion: { ( ( societeData.completedActions / ( societeData.completedActions + societeData.leftActions ) ) * 100 ) } %</span>
                                                        <span style={{ fontSize: 12, backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: 6, marginRight: 10, borderRadius: 5 }}><span className="material-symbols-outlined" style={{ fontSize: 20, cursor: 'pointer', verticalAlign: 'middle' }}>monitoring</span> Completed actions: { societeData.completedActions }</span>
                                                        <span style={{ fontSize: 12, backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: 6, borderRadius: 5 }}><span className="material-symbols-outlined" style={{ fontSize: 20, cursor: 'pointer', verticalAlign: 'middle' }}>person</span> Left actions: { societeData.leftActions }</span>
                                                    </div>
                                                )
                                            }
                                            else
                                            {
                                                return (
                                                    <div key={societe.id} style={{ backgroundColor: '#34B764', color: '#FFFFFF', padding: 10, paddingTop: 0, margin: 10 }}>
                                                        <p style={{ fontSize: 12, fontWeight: 700 }}>{ societe.name }</p>
                                                        <span style={{ fontSize: 12, backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: 6, marginRight: 10, borderRadius: 5 }}><span className="material-symbols-outlined" style={{ fontSize: 20, cursor: 'pointer', verticalAlign: 'middle' }}>check_box</span> Complétion: 0 %</span>
                                                        <span style={{ fontSize: 12, backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: 6, marginRight: 10, borderRadius: 5 }}><span className="material-symbols-outlined" style={{ fontSize: 20, cursor: 'pointer', verticalAlign: 'middle' }}>monitoring</span> Completed actions: 0</span>
                                                        <span style={{ fontSize: 12, backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: 6, borderRadius: 5 }}><span className="material-symbols-outlined" style={{ fontSize: 20, cursor: 'pointer', verticalAlign: 'middle' }}>person</span> Left actions: 0</span>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
