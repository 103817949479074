import graviteImg from "../assets/images/matrice_gravite.jpg";
import priorisationImg from "../assets/images/matrice_priorisation.jpg";
import maitriseImg from "../assets/images/risque_brut_maitrise.jpg";
import {useEffect, useState} from "react";
import RiskTopMenu from "../Components/RiskTopMenu";
import RiskSideMenu from "../Components/RiskSideMenu";
import {getColorHexCode, isEmpty, SendGet} from "../State/Helper";
import OccurrenceImpactShape from "../Components/OccurrenceImpactShape";
import CircleMenu from "../Components/CircleMenu";
import Details from "../Components/Details";
import {useAuth} from "../State/useAuth";
import { primaryColor } from "../configuration/colorPalet";
import NotificationBadge from "../Components/NotificationBadge";

export default function Graphics() {

    const [showSideMenu, setShowSideMenu] = useState(true);
    const [showMaitrise, setShowMaitrise] = useState(false);
    const [showMatrice, setShowMatrice] = useState(false);
    const [showGravity, setShowGravity] = useState(false);
    const [occurrenceImpactData, setOccurrenceImpactData] = useState([]);
    const [scores, setScores] = useState([]);
    const [showDetail, setShowDetail] = useState(null);
    const [gravities, setGravities] = useState([]);
    const {cartographie, businessUnit, country, company, businessActivity, searchKeyword} = useAuth();

    async function riskBrutMaitrise() {

        try
        {
            // Get all the risks for the current logged in user
            let riskResponse = await SendGet(`api/v1/risks/currentuser`);

            // Get the risks array from the response
            let risksList = riskResponse.data;

            let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

            // Filter by cartographie AKA (Mapping)
            if( cartographie.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.mappingId) === parseInt(cartographie.value));
            }

            // Filter by businessUnit
            if( businessUnit.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.buId) === parseInt(businessUnit.value));
            }

            // Filter by country
            if( country.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.countryId) === parseInt(country.value));
            }

            // Filter by company
            if( company.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.companyId) === parseInt(company.value));
            }

            // Filter by BusinessActivity
            if( businessActivity.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.baId) === parseInt(businessActivity.value));
            }

            // Filter by search keyword
            if( searchKeyword.value !== null )
            {
                risksList = risksList.filter(risk => ( risk.enTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase()) || risk.frTitle.toLowerCase().includes(parseSearchKeyword.toLowerCase())));
            }

            let data = [];

            // Loop Through the risks
            await Promise.all(risksList.map(async risk => {

                // Get all assessments for a specific risk
                const assessmentsResponse = await SendGet(`api/v1/assessments/risk/${risk.id}`);

                // Make sure the risk has some assessments
                if ( !isEmpty(assessmentsResponse.data) )
                {
                    // Get latest version assessment
                    const assessment = assessmentsResponse.data.reduce((max, obj) => parseInt(obj.version) > parseInt(max.version) ? obj : max);

                    if( !isEmpty(assessment) )
                    {
                        // Append the calculated assessment to the list
                        data.push({
                            x: assessment.maitrise !== 0 ? parseFloat(assessment.maitrise).toFixed(1) : 0.75, // Check if the maitrise is 0 if so add 1 to it in order to prevent the chart rendering issue
                            y: parseFloat(assessment.riskBruteNormalise).toFixed(1),
                            risk: risk
                        });
                    }
                }

            }));

            setScores(data);

        } catch (error)
        {
            console.error(`Error when getting risks: `, error);
        }
    }

    const getCircleStyle = (maitrise, riskBrutNormalise) => {

        return {
            left: getX(maitrise),
            bottom: getY(riskBrutNormalise),
            position: 'absolute',
            width: `50px`,
            height: `50px`,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            borderRadius: '50%',
            border: '1px dashed #000'
        }
    };

    function getX(maitrise) {

        // Check if the maitrise is 0 if so add 1 to it in order to prevent the chart rendering issue
        if( maitrise === 0 )
        {
            maitrise = 0.65;
        }

        return ( ( parseFloat(maitrise).toFixed(1) - 1 ) * (900 / 6) + ( ((900 / 6) - 50) / 2 ) );
    }

    function getY(riskBrutNormalise) {
        return ( ((parseFloat(riskBrutNormalise).toFixed(1) - 1) * (600 / 6)) + (((600 / 6) - 50) / 2) );
    }

    function convertToScaleOf4(value) {
        const proportionalValue = (value / 6) * 4;
        return parseFloat(proportionalValue).toFixed(1);
    }

    async function getOccurrenceImpactData() {

        try
        {
            let data = [];

            let riskResponse = await SendGet(`api/v1/risks/currentuser`);

            let risksList = riskResponse.data;

            let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

            // Filter by cartographie AKA (Mapping)
            if( cartographie.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.mappingId) === parseInt(cartographie.value));
            }

            // Filter by businessUnit
            if( businessUnit.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.buId) === parseInt(businessUnit.value));
            }

            // Filter by country
            if( country.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.countryId) === parseInt(country.value));
            }

            // Filter by company
            if( company.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.companyId) === parseInt(company.value));
            }

            // Filter by BusinessActivity
            if( businessActivity.value !== null )
            {
                risksList = risksList.filter(risk => parseInt(risk.baId) === parseInt(businessActivity.value));
            }

            // Filter by search keyword
            if( searchKeyword.value !== null )
            {
                risksList = risksList.filter(risk => ( risk.enTitle.includes(parseSearchKeyword) || risk.frTitle.includes(parseSearchKeyword )));
            }

            await Promise.all(risksList.map(async (risk) => {

                const assessments = await SendGet(`api/v1/assessments/risk/${risk.id}`);

                if ( !isEmpty(assessments.data) )
                {
                    const assessment = assessments.data.reduce((max, obj) => parseInt(obj.version) > parseInt(max.version) ? obj : max);

                    if( !isEmpty(assessment) )
                    {
                        data.push({
                            occurrence: Math.round(assessment.occurrence),
                            impact: Math.round(parseInt(assessment.impact))
                        });
                    }
                }

            }));

            setOccurrenceImpactData(data);

        } catch (error) {
            console.error(error);
        }
    }

    async function getGraviteMaitrise() {

        /*

        let data = [];

        let riskResponse = await SendGet(`api/v1/risks/currentuser`);

        let risksList = riskResponse.data;

        let parseSearchKeyword = searchKeyword.value !== null ? searchKeyword.value : null;

        // Filter by cartographie AKA (Mapping)
        if( cartographie.value !== null )
        {
            risksList = risksList.filter(risk => parseInt(risk.mappingId) === parseInt(cartographie.value));
        }

        // Filter by businessUnit
        if( businessUnit.value !== null )
        {
            risksList = risksList.filter(risk => parseInt(risk.buId) === parseInt(businessUnit.value));
        }

        // Filter by country
        if( country.value !== null )
        {
            risksList = risksList.filter(risk => parseInt(risk.countryId) === parseInt(country.value));
        }

        // Filter by company
        if( company.value !== null )
        {
            risksList = risksList.filter(risk => parseInt(risk.companyId) === parseInt(company.value));
        }

        // Filter by BusinessActivity
        if( businessActivity.value !== null )
        {
            risksList = risksList.filter(risk => parseInt(risk.baId) === parseInt(businessActivity.value));
        }

        // Filter by search keyword
        if( searchKeyword.value !== null )
        {
            risksList = risksList.filter(risk => ( risk.enTitle.includes(parseSearchKeyword) || risk.frTitle.includes(parseSearchKeyword )));
        }

        await Promise.all(risksList.map(async risk => {

            try
            {
                let assessments = await SendGet(`api/v1/assessments/risk/${risk.id}`);

                if (!isEmpty(assessments.data)) {

                    let assessment = assessments.data.reduce((max, obj) => parseInt(obj.version) > parseInt(max.version) ? obj : max);

                    if (!isEmpty(assessment)) {

                        let current_maitrise = assessment.maitrise !== 0 ? parseFloat(assessment.maitrise).toFixed(2) : 0.65;
                        let gravite = ( parseFloat(assessment.impact).toFixed(2) * parseFloat(assessment.occurrence).toFixed(2) ) / current_maitrise;

                        data.push({
                            risk: risk,
                            gravite: gravite,
                            maitrise: current_maitrise
                        });
                    }
                }
            } catch (error) {
                console.error('Error in getGraviteMaitrise:', error);
            }
        }));

        */

        setGravities([]);
    }

    async function getDetails(riskId) {

        try
        {
            // Get the risk with the specific ID
            const riskResponse = await SendGet(`api/v1/risks/${riskId}`);

            // Check if the risk actually exists
            if ( !isEmpty(riskResponse.data) )
            {
                // Get assessments for the specific risk
                const assessmentsResponse = await SendGet(`api/v1/assessments/risk/${riskId}`);

                // Check if there is assessments related to that risk
                if ( !isEmpty(assessmentsResponse.data) )
                {
                    // Get the assessment with the highest version number
                    const activeAssessment = assessmentsResponse.data.reduce((max, obj) => parseInt(obj.version) > parseInt(max.version) ? obj : max);

                    // Check if it actually has some value
                    if ( !isEmpty(activeAssessment) )
                    {
                        // Set the risk details
                        setShowDetail({
                            riskBrute: activeAssessment.riskBrute,
                            riskNet: activeAssessment.riskNet,
                            riskBruteNormalise: activeAssessment.riskBruteNormalise,
                            riskNetNormalise: activeAssessment.riskNetNormalise,
                            maginProgressPercentage: activeAssessment.marginProgressPercentage,
                            impact: activeAssessment.impact,
                            normalisedProgressPercentage: activeAssessment.normalisedProgressPercentage,
                            maitrise: activeAssessment.maitrise,
                            risk: riskResponse.data
                        });
                    }
                }
            }

        } catch (error) {
            console.error(`An error occurred: ${error}`);
        }
    }

    useEffect(() => {
        async function fetch() {
            await riskBrutMaitrise();
            await getOccurrenceImpactData();
            await getGraviteMaitrise();
        }
        fetch().catch(e => console.log(e));
    }, [cartographie, businessUnit, country, company, businessActivity, searchKeyword]);

    const styles = {
        container: {
            display: 'flex',
            flex: 6,
            flexDirection: 'column',
            padding: 20,
            backgroundColor: '#FFFFFF'
        },
        sectionContainer: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            border: 'solid 1px #E2E2E2',
            borderRadius: 5,
            cursor: 'pointer'
        },
        sectionTitle: {
            fontSize: 16,
            textTransform: 'uppercase',
            fontWeight: 700,
            marginTop: 35,
            color: primaryColor,
            flex: 2
        },
        sectionImage: {
            height: 50,
            width: 80,
            padding: 20
        },
        sectionIcon: {
            color: primaryColor,
            fontSize: 30,
            fontWeight: 700,
            verticalAlign: 'middle',
            marginTop: 30,
            marginRight: 10
        },
        mainContainer: {
            backgroundColor: '#FFFFFF',
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer'
        },
        chartContainer: {
            borderTop: 'none',
            borderRight: 'none',
            background: `linear-gradient(to top right, ${ getColorHexCode(1) }, ${ getColorHexCode(4) }, ${ getColorHexCode(5) }, ${ getColorHexCode(6) })`,
            width: `900px`,
            height: `600px`,
            border: '1px solid black',
            position: 'relative',
            borderLeft: 'solid 5px #000000',
            marginTop: 50
        },
        chartRow: {
            height: 50,
            display: 'flex',
            flexDirection: 'row',
            flex: 0.8,
        },
        chartColumn: {
            flex: 0.8,
            border: 'solid 0.2px #C9A457'
        },
        verticalNumbering: {
            flex: 0.8,
            color: '#000000',
            fontWeight: 600
        },
        horizontalNumbering: {
        },
        chartCircle: {
            backgroundColor: '#FFFFFF', width: 80, height: 80, borderRadius: '50%', zIndex: 9999
        }
    }

    return (

        <>
            <RiskTopMenu setShowSideMenu={() => setShowSideMenu(!showSideMenu)} showSideMenu={showSideMenu} />
            <div style={{ display: 'flex' }}>
                <RiskSideMenu showSideMenu={showSideMenu} selectedTab="graphic"  />
                <div style={styles.container}>

                    <NotificationBadge />

                    <div style={styles.sectionContainer} onClick={() => setShowMaitrise(!showMaitrise)}>
                        <img style={styles.sectionImage} alt="image" src={maitriseImg} />
                        <p style={styles.sectionTitle}>RISQUE BRUT & MAITRISE</p>
                        { showMaitrise === false && <span className="material-symbols-outlined" style={styles.sectionIcon}>navigate_next</span> }
                        { showMaitrise === true && <span className="material-symbols-outlined" style={styles.sectionIcon}>expand_more</span> }
                    </div>

                    { showMaitrise &&

                    <div style={{ ...styles.mainContainer, margin: '0px auto', textAlign: 'center' }}>

                        <div style={{ textAlign: "center" }}>
                            <p style={{ color: '#000000', fontSize: 20, fontWeight: 600, verticalAlign: 'middle', transform: "rotate(-90deg)", marginTop: 350, flex: 1, marginRight: -50 }}>RISQUE BRUT</p>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', height: 600, marginTop: 90, justifyContent: 'center', alignItems: 'center', marginRight: 15, marginLeft: 20 }}>
                            <p style={styles.verticalNumbering}>6</p>
                            <p style={styles.verticalNumbering}>5</p>
                            <p style={styles.verticalNumbering}>4</p>
                            <p style={styles.verticalNumbering}>3</p>
                            <p style={styles.verticalNumbering}>2</p>
                            <p style={styles.verticalNumbering}>1</p>
                        </div>

                        <div style={{ flex: 5, display: 'flex', flexDirection: 'column' }}>

                            <div style={styles.chartContainer}>
                                {
                                    !isEmpty(scores) && scores.map((score, index) => (
                                        <div
                                            key={ index }
                                            onClick={ () => getDetails(score.risk.id) }
                                            style={ getCircleStyle(parseFloat(score.x).toFixed(2), parseFloat(score.y).toFixed(2)) }
                                        />
                                    ))
                                }
                            </div>

                            { showDetail !== null && <Details isVisible={true} data={showDetail} handleClose={() => setShowDetail(null)} /> }

                            <div style={{ ...styles.chartRow, borderTop: 'solid 5px #000000', borderLeft: 'none', width: 900, marginLeft: 5, paddingTop: 10 }}>
                                <div style={{ ...styles.chartColumn, border: 'none', textAlign: 'center', color: '#000000', fontWeight: 600 }}>1</div>
                                <div style={{ ...styles.chartColumn, border: 'none', textAlign: 'center', color: '#000000', fontWeight: 600 }}>2</div>
                                <div style={{ ...styles.chartColumn, border: 'none', textAlign: 'center', color: '#000000', fontWeight: 600 }}>3</div>
                                <div style={{ ...styles.chartColumn, border: 'none', textAlign: 'center', color: '#000000', fontWeight: 600 }}>4</div>
                                <div style={{ ...styles.chartColumn, border: 'none', textAlign: 'center', color: '#000000', fontWeight: 600 }}>5</div>
                                <div style={{ ...styles.chartColumn, border: 'none', textAlign: 'center', color: '#000000', fontWeight: 600 }}>6</div>
                            </div>

                            <div style={{ marginLeft: '25%' }}>
                                <p style={{ color: '#000000', fontSize: 20, fontWeight: 600 }}>MAITRISE</p>
                            </div>

                        </div>
                    </div>
                    }

                    <div style={{ ...styles.sectionContainer, marginTop: 20 }} onClick={() => setShowMatrice(!showMatrice)}>
                        <img style={styles.sectionImage} alt="image" src={graviteImg} />
                        <p style={styles.sectionTitle}>Matrice de gravité (occurrence & impact)</p>
                        { showMatrice === false && <span className="material-symbols-outlined" style={styles.sectionIcon}>navigate_next</span> }
                        { showMatrice === true && <span className="material-symbols-outlined" style={styles.sectionIcon}>expand_more</span> }
                    </div>

                    { showMatrice && <OccurrenceImpactShape data={occurrenceImpactData} /> }

                    {

                    showGravity &&

                        <div style={{ backgroundColor: '#FFFFFF', padding: 50 }}>

                            <div style={{ display: 'flex', flexDirection: 'row' }} >

                                <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>

                                    <div style={{ flex: 2, marginRight: 50 }}>
                                        <div style={{ textAlign: "center" }}>
                                            <p style={{ color: '#000000', fontSize: 20, fontWeight: 600, verticalAlign: 'middle', transform: "rotate(-90deg)", marginTop: 200, flex: 1 }}>GRAVITE <span style={{ fontSize: 14 }}>(Occurrence & Impact)</span></p>
                                        </div>
                                    </div>

                                    <div style={{ flex: 4 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: 20, height: '600px' }}>
                                            <div style={{ flex: 1.5, color: '#FFFFFF', paddingTop: '100%', textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#E24144', borderTopRightRadius: 10, borderTopLeftRadius: 10 }}>4</div>
                                            <div style={{ flex: 1.5, color: '#FFFFFF', paddingTop: '100%', textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#E17F28' }}>3</div>
                                            <div style={{ flex: 1.5, color: '#FFFFFF', paddingTop: '100%', textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#D0C32A' }}>2</div>
                                            <div style={{ flex: 1.5, color: '#FFFFFF', paddingTop: '100%', textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#1DBB6E', borderBottomRightRadius: 10, borderBottomLeftRadius: 10 }}>1</div>
                                        </div>
                                    </div>

                                </div>

                                <div style={{ flex: 5 }}>

                                    <div style={{ width: 900, height: 600, position: 'relative' }} >

                                        <div style={{  flex: 1, display: 'flex', borderLeft: 'solid 8px #094492', width: `900px` }}>
                                            <div style={{width: `225px`, height: `150px`, backgroundColor: '#FCC2C3', border: 'solid 1px #FFFFFF'}}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#FCC2C3', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#FCC2C3', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#67D0FA', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#67D0FA', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#67D0FA', border: 'solid 1px #FFFFFF' }}/>
                                        </div>
                                        <div style={{  flex: 1, display: 'flex', borderLeft: 'solid 8px #094492', width: `900px` }}>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#FCC2C3', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#FCC2C3', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#FCC2C3', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#67D0FA', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#67D0FA', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#67D0FA', border: 'solid 1px #FFFFFF' }}/>
                                        </div>
                                        <div style={{  flex: 1, display: 'flex', borderLeft: 'solid 8px #094492', width: `900px` }}>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#DAE8FA', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#DAE8FA', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#DAE8FA', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#EDEEF4', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#EDEEF4', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#EDEEF4', border: 'solid 1px #FFFFFF' }}/>
                                        </div>
                                        <div style={{  flex:1, display: 'flex', borderLeft: 'solid 8px #094492', width: `900px`, borderBottom: 'solid 8px #094492' }}>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#DAE8FA', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#DAE8FA', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#DAE8FA', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#EDEEF4', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#EDEEF4', border: 'solid 1px #FFFFFF' }}/>
                                            <div style={{ width: `225px`, height: `150px`, backgroundColor: '#EDEEF4', border: 'solid 1px #FFFFFF' }}/>
                                        </div>

                                        {
                                            !isEmpty(gravities) && gravities.map((item, index) => {

                                                return (
                                                    <span
                                                        key={index}
                                                        style={{
                                                                padding:5,
                                                                borderRadius: 5,
                                                                position: 'absolute',
                                                                backgroundColor:'#2F87FC',
                                                                color:'#FFFFFF',
                                                                bottom: `${convertToScaleOf4(getY(item.gravite))}px`,
                                                                left: `${getX(item.maitrise)}px` }}
                                                    >{`${ convertToScaleOf4( parseFloat(item.gravite).toFixed(1) ) } : ${parseFloat(item.maitrise).toFixed()}`}</span>
                                                )
                                            })
                                        }

                                    </div>

                                    <div style={{ ...styles.chartRow, borderTop: 'solid 5px #094492', borderLeft: 'none', width: 900, marginLeft: 5, paddingTop: 5, marginTop: 10 }}>
                                        <div style={{ ...styles.chartColumn, border: 'none', textAlign: 'center', color: '#094492', fontWeight: 600 }}>1</div>
                                        <div style={{ ...styles.chartColumn, border: 'none', textAlign: 'center', color: '#094492', fontWeight: 600 }}>2</div>
                                        <div style={{ ...styles.chartColumn, border: 'none', textAlign: 'center', color: '#094492', fontWeight: 600 }}>3</div>
                                        <div style={{ ...styles.chartColumn, border: 'none', textAlign: 'center', color: '#094492', fontWeight: 600 }}>4</div>
                                        <div style={{ ...styles.chartColumn, border: 'none', textAlign: 'center', color: '#094492', fontWeight: 600 }}>5</div>
                                        <div style={{ ...styles.chartColumn, border: 'none', textAlign: 'center', color: '#094492', fontWeight: 600 }}>6</div>
                                    </div>

                                    <div style={{ textAlign: "center" }}>
                                        <p style={{ color: '#094492', fontSize: 20, fontWeight: 600 }}>MAITRISE</p>
                                    </div>

                                </div>

                            </div>

                        </div>

                    }

                    </div>

                <CircleMenu />

            </div>
        </>
    );
}
