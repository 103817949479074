import React, { useState } from 'react';
import {getColorHexCode} from "../State/Helper";

const Success = ({ sucessMessage, isVisible, handleClose }) => {

    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)', /* Semi-transparent black overlay */
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const contentStyle = {
        background: getColorHexCode(1), /* White background for the popup */
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        textAlign: 'center',
        position: 'relative',
        color: '#FFFFFF'
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '18px',
        cursor: 'pointer',
    };

    return (
        isVisible && (
            <div style={overlayStyle}>
                <div style={contentStyle}>
                    <span style={closeButtonStyle} onClick={handleClose}>&times;</span>
                    <p><span className="material-symbols-outlined" style={{ color: '#FFFFFF', verticalAlign: 'middle', fontSize: 30 }}>check</span> {sucessMessage}</p>
                </div>
            </div>
        )
    );
};

export default Success;
