import {formatDate, getColorHexCode} from "../State/Helper";
import ProgressBar from "@ramonak/react-progress-bar";

export default function EvaluationCard ({ item, origin }) {

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: getColorHexCode( parseFloat(item.riskNetNormalise).toFixed(2) ),
            color: '#FFFFFF',
            borderRadius: 10,
            fontSize: 12,
            padding: 10,
            marginTop: 10
        },
        cardTagContainer: {
            flex: 2.2,
            padding: 15,
        },
        cardTag: {
            backgroundColor: '#877E17',
            color: '#FFFFFF',
            padding: 5,
            paddingTop: 5,
            fontSize: 11,
            textAlign: 'left',
            borderRadius: 10,
            paddingRight: 10
        },
        iconStyle: {
            fontSize: 12,
            color: '#FFFFFF',
            marginRight: 5
        },
        riskLabel: {
            color: '#FFFFFF',
            fontSize: 12,
            flex: 1
        },
        riskSection: {
            flex: 0.6,
            paddingTop: 12,
            display: 'flex',
            flexDirection: 'row'
        },
        fullRate: {
            fontSize: 10,
            marginRight: 5,
            verticalAlign: 'middle',
            color: '#FFFFFF',
            backgroundColor: '#FFFFFF',
            borderRadius: '50%'
        },
        emptyRate: {
            fontSize: 10,
            marginRight: 5,
            verticalAlign: 'middle',
            color: '#FFFFFF'
        }
    }

    return (
        <>
            <br />
            <span style={{ color: '#767676', fontSize: 14, fontWeight: 'bold' }}>
                <span className="material-symbols-outlined" style={{ ...styles.iconStyle, color: '#767676', flex: 0.2, fontSize: 20, verticalAlign: 'middle', marginTop: -4 }}>calendar_month</span> VERSION {item.version} | {formatDate(item.credate)}
            </span>
            <div style={styles.container}>

                <span style={{ ...styles.cardTagContainer, flex: 1.5 }}>
                    <span style={{ ...styles.cardTag, textTransform: 'uppercase', backgroundColor: 'rgba(0, 0, 0, 0.4)' }}><span className="material-symbols-outlined" style={{ ...styles.iconStyle, fontSize: 16, verticalAlign: 'middle', marginTop: -5 }}>apartment</span> {origin}</span>
                </span>

                <span style={{ ...styles.riskSection, flex: 0.5 }}>Risque brut&nbsp;&nbsp;&nbsp;<span style={{ fontWeight: 700, fontSize: 14 }}>{parseFloat(item.riskBruteNormalise).toFixed(2)}</span> </span>
                <span style={{ ...styles.riskSection, flex: 0.5 }}>Risque net&nbsp;&nbsp;&nbsp;<span style={{ fontWeight: 700, fontSize: 14 }}>{parseFloat(item.riskNetNormalise).toFixed(2)}</span></span>

                <span style={styles.riskSection}>Impact&nbsp;&nbsp;&nbsp;
                    <ProgressBar labelSize={9} className="progressBarwrapper" labelColor={ getColorHexCode( parseFloat(item.riskNetNormalise).toFixed(2) ) } baseBgColor="rgba(255, 255, 255, 0.3)" bgColor="#FFFFFF" completed={ Math.round((item.impact / 6) * 100) } width={140} />
                </span>

                <span style={styles.riskSection}>Occurrence&nbsp;&nbsp;&nbsp;
                    <ProgressBar labelSize={9} className="progressBarwrapper" labelColor={ getColorHexCode( parseFloat(item.riskNetNormalise).toFixed(2) ) } baseBgColor="rgba(255, 255, 255, 0.3)" bgColor="#FFFFFF" completed={ Math.round((item.occurrence / 6) * 100) } width={140} />
                </span>

                <span style={styles.riskSection}>Maitrise&nbsp;&nbsp;&nbsp;
                    <ProgressBar labelSize={9} className="progressBarwrapper" labelColor={ getColorHexCode( parseFloat(item.riskNetNormalise).toFixed(2) ) } baseBgColor="rgba(255, 255, 255, 0.3)" bgColor="#FFFFFF" completed={ Math.round( ( ( (item.competence + item.procedure + item.tracabilite + item.kpi)/4 ) / 6 ) * 100 ) } width={140} />
                </span>

            </div>
        </>
    );
}
