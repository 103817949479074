import React, { useEffect, useState } from 'react';
import {useAuth} from "../State/useAuth";
import { useNavigate } from 'react-router-dom';
import { checkPermission } from '../State/Helper';

const CircleMenu = () => {

  const [isOpen, setIsOpen] = useState(false);
  const {cartographie, businessUnit, country, company, businessActivity} = useAuth();
  const [hasRight, setHasRight] = useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {

    if( option.id === 1 )
    {
      navigate(`/risk/create`);
    }

  };

  async function getPermission() {

    const response = await checkPermission('CREATE', null, null);
    setHasRight(response);
  }

  useEffect(() => {
    getPermission();
  }, []);

  const circleMenuStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    width: '60px',
    height: '60px',
    backgroundColor: isOpen ? '#FFFFFF' : '#FFFFFF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    border: 'solid 1px #000000'
  };

  const submenuStyle = {
    position: 'absolute',
    bottom: '80px',
    right: '30px',
    display: isOpen ? 'flex' : 'none',
    flexDirection: 'column',
    background: '#FFFFFF',
    border: 'solid 1px #000000',
    borderRadius: '8px',
    padding: '10px',
  };

  const optionStyle = {
    borderRadius: '5px',
    padding: '8px',
    margin: '1px',
    color: '#000000',
    fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: 12,
    transition: 'background-color 0.3s ease',
  };

  const options = [{
    id: 1,
    label: 'Ajouter un risque'
  }];

  return ( hasRight && country.value && company.value && cartographie.value && businessUnit.value && businessActivity.value &&
    <div>
      <div style={circleMenuStyle} onClick={toggleMenu}>
        { !isOpen && <span className="material-symbols-outlined" style={{ color: '#000000', fontSize: '25px' }}>menu</span> }
        { isOpen && <span className="material-symbols-outlined" style={{ color: '#000000', fontSize: '25px' }}>close</span> }
      </div>
      <div style={submenuStyle}>
        {options.map((option, index) => (
          <div key={option.id} style={optionStyle} onClick={() => handleOptionClick(option)}>
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CircleMenu;
