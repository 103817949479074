import RiskSideMenu from "../Components/RiskSideMenu";
import RiskTopMenu from "../Components/RiskTopMenu";

const styles = {
    mainContainer: {

    },
    sideMenuContainer: {
    },
    contentContainer: {
        flex: 5,
        display: 'flex',
        flexDirection: 'column',
        padding: 20
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 50
    },
    primaryLabel: {
        fontSize: 28,
        color: '#000000',
        flex: 1
    },
    primaryButton: {
        color: '#000000',
        backgroundColor: '#FFFFFF',
        borderRadius: 20,
        fontSize: 13,
        fontWeight: 600,
        margin: 40,
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        textTransform: 'uppercase',
        border: 'solid 1px #000000',
        cursor: 'pointer'
    },
    iconStyle: {
        fontSize: 20,
        fontWeight: 500,
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center'
    }
}

export default function Export() {
    return (
        <div style={styles.mainContainer}>
            <RiskTopMenu />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={styles.sideMenuContainer}>
                    <RiskSideMenu showSideMenu={true}/>
                </div>
                <div style={styles.contentContainer}>
                    <div style={styles.headerContainer}>
                        <span style={styles.primaryLabel}>Export the current mapping</span>
                    </div>
                    <div style={styles.buttonContainer}>
                        <button style={styles.primaryButton} className="btn-primary-custom" onClick={() => alert('Export functionality is under development ...')}><span
                            className="material-symbols-outlined" style={styles.iconStyle}>exit_to_app</span> export the
                            mapping
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
