import React, { useState } from 'react';
import RiskCardList from "./RiskCardList";
import { primaryColor, lighGrey } from "../configuration/colorPalet";
import NotificationBadge from './NotificationBadge';

const ListPaginator = ({ items, itemsPerPage }) => {
    // State to track current page
    const [currentPage, setCurrentPage] = useState(1);

    // Calculate total pages
    const totalPages = Math.round(items.length / itemsPerPage);

    // Calculate the slice of items to display
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = items.slice(startIndex, endIndex);

    // Function to change page
    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const styles = {
        container: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            padding: 20,
        },
        paginatorContainer: {
            margin: 20
        },
        paginationBtn: {
            color: '#FFFFFF',
            backgroundColor: primaryColor,
            padding: 5,
            border: `solid 1px ${primaryColor}`,
            borderRadius: 4,
            margin: 2,
            cursor: 'pointer',
            paddingLeft: 5,
            paddingRight: 5,
            fontWeight: 700
        },
        paginationBtnDisabled: {
            color: primaryColor,
            backgroundColor: lighGrey,
            padding: 5,
            border: `solid 1px ${lighGrey}`,
            borderRadius: 4,
            margin: 2,
            paddingLeft: 5,
            paddingRight: 5,
            cursor: 'pointer',
            fontWeight: 700
        }
    }

    return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            {/* Display the items for the current page */}
            <div style={styles.container}>
                <NotificationBadge />
                {currentItems.map((item, index) => (
                    <RiskCardList key={index} risk={item} frTitle={item.frTitle} mappingId={item.mappingId} />
                ))}
            </div>
            {/* Pagination Controls */}
            <div style={styles.paginatorContainer}>
                {/* Previous Page Button */}
                <button style={{ ...(currentPage === 1 ? styles.paginationBtnDisabled : styles.paginationBtn) }} onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>
                    Précédent
                </button>

                {/* Page Numbers (simplified for this example) */}
                {[...Array(totalPages).keys()].map(pageNumber => (
                    <button style={{ ...(currentPage === pageNumber + 1 ? styles.paginationBtnDisabled : styles.paginationBtn) }} key={pageNumber + 1} onClick={() => goToPage(pageNumber + 1)} disabled={currentPage === pageNumber + 1}>
                        {pageNumber + 1}
                    </button>
                ))}

                {/* Next Page Button */}
                <button style={{ ...(currentPage === totalPages ? styles.paginationBtnDisabled : styles.paginationBtn) }} onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>
                    Suivant
                </button>
            </div>
        </div>
    );
};

export default ListPaginator;
