import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './useAuth';
import { checkPermission, isEmpty } from "./Helper";

const ProtectedRoute = ({ children, requireAdmin = false, right = false }) => {

    const { auth, isLoading } = useAuth();
    const location = useLocation();
    const [permissionCheckCompleted, setPermissionCheckCompleted] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);

    useEffect(() => {
        const checkPermissions = async () => {
            
            let hasAdmin = false;
            let hasRightPermission = false;

            if (requireAdmin) {
                hasAdmin = await checkPermission('ADMIN');
                if (!hasAdmin) {
                    setAccessDenied(true);
                    return;
                }
            }

            if (!requireAdmin && right) {
                hasRightPermission = await checkPermission(right);
                if (!hasRightPermission) {
                    setAccessDenied(true);
                    return;
                }
            }

            setPermissionCheckCompleted(true);
        };

        checkPermissions();
    }, [requireAdmin, right]);

    if (isLoading || !permissionCheckCompleted) {
        return <div>Loading...</div>;
    }

    if (isEmpty(auth.user)) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!permissionCheckCompleted || accessDenied) {
        return <Navigate to="/denied" state={{ from: location }} replace />;
    }

    return children;
};

export default ProtectedRoute;