import {useEffect, useState} from "react";
import {useAuth} from "../State/useAuth";


export default function MinMenuItem({ iconName="NaN", label="NaN", active=false, onClick }) {

    const [iconType, setIconType] = useState("material-symbols-outlined");
    const { activeMenu } = useAuth();
    const styles = {
        minMenuContainer: {
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            padding: 15,
            paddingLeft: 5,
            cursor: "pointer"
        },
        minMenuIcon: {
            fontSize: 25,
            fill: 1
        },
        minMenuLabel: {
            fontSize: 10,
            marginTop: 5
        }
    }

    useEffect(() => {

        if( String(activeMenu).toLowerCase() === String(label).toLowerCase() )
        {
            setIconType("material-symbols-outlined material-symbols-filled");
        }
        else
        {
            setIconType("material-symbols-outlined");
        }

    }, [activeMenu]);


    return (
        <div style={styles.minMenuContainer} onClick={onClick}>
            <span style={styles.minMenuIcon} className={iconType}  >{iconName}</span>
            <span style={styles.minMenuLabel}>{label}</span>
        </div>
    );
}
